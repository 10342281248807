<template>
  <div>
    <Nav></Nav>
    <b-container fluid>
      <b-row>
        <b-col>
          <h1>Rappi Tiendas</h1>
        </b-col>
      </b-row>

      <!-- <b-row>
        <b-col>
          <b-form-group label="Buscar:">
            <b-form-input type="search" v-model="search" @keyup="page=1" debounce="500"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row> -->
      <b-row>
        <b-col xs="12" sm="6" md="6" lg="4" xl="3" v-for="sucursal in storesTable " v-bind:key="sucursal.id">
          <b-card class="">
            <b-row>
              <b-col>
                {{ sucursal.name }}<br>{{ sucursal.id }}<br>Menu: {{ sucursal.menu }}<br><b-badge pill
                  v-if="sucursal.parent_id == null" variant="success">DADDY</b-badge><b-badge pill v-else
                  variant="primary">NO DADDY: {{
          sucursal.parent_id }}</b-badge>
              </b-col>
              <b-col cols="auto">
                <b-button size="sm" :disabled="sucursal.parent_id != null" variant="success"
                  @click="sendRappiMenu(sucursal.id)">Enviar Menu</b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <!-- <b-row>
        <b-col>
          <b-table
            stacked="sm"
            :filter="search"
            striped
            hover
            :fields="tableFields"
            :items="storesTable"
            responsive="md"
            :per-page="byPage"
            :current-page="page"
          >
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>

<template #cell(buttons)="data">
              <b-button-group vertical>
                <b-button size="sm" variant="success" @click="$router.push('/sucursal/' + data.item.id)"
                  >Ver Más +</b-button
                >
                
              </b-button-group>
            </template>
<template #cell(group)="data">
              {{searchGroup(data.item.group).name}}
            </template>
<template #cell(subgroup)="data">
              {{searchSubgroup(data.item.subgroup).name}}
            </template>
<template #cell(status)="data">
                <b-badge pill v-if="data.item.status" variant="success">ACTIVO</b-badge>
                <b-badge pill v-else variant="danger">INACTIVO</b-badge>
            </template>
<template #cell(image)="data">
              <img style="max-width: 8vh;" :src="axios.defaults.baseURL + '/' +data.item.image">
            </template>
</b-table>
</b-col>
</b-row>
<b-row>
  <b-col>
    <b-pagination v-model="page" :total-rows="storesTable.length" :per-page="byPage"
      aria-controls="my-table"></b-pagination>
  </b-col>
</b-row> -->
    </b-container>
  </div>
</template>
<script>
import Nav from "../components/Nav.vue";
import axios from "axios";
export default {
  name: "RappiStores",
  components: { Nav },
  data() {
    return {
      sucursalesDesconectadas: null,
      sucursalesEnLinea: null,
      byPage: 20,
      page: 1,
      storesTable: [],
      search: "",
      rappiMenus: [],
      rappiMenusOptions: [],
      tableFields: [
        { key: "id", label: "ID", sortable: true, sortDirection: "desc", class: "align-middle" },
        { key: "nombre", label: "Nombre", sortable: true, class: "align-middle" },
        { key: "version", label: "Version", sortable: true },
        { key: "status", label: "Status", sortable: true },
        { key: "buttons", label: "" },
      ],
    };
  },
  activated() {
  },
  created() {
    this.getAllrappiMenus()

  },
  methods: {
    getAllrappiMenus() {
      let that = this;
      console.log("Getting Rappi menus")
      axios
        .get("/rappi/menus")
        .then(function (response) {
          that.rappiMenus = response.data;
          console.log(response.data)
          let persfil = response.data
            .map((pers) => {
              return { id: pers.id, label: pers.name };
            });
          let persfils = [{ id: undefined, label: "" }]
          persfils.push(...persfil)
          that.rappiMenusOptions = persfils;
          that.getAllRappiStores();

        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    cleanNewSucursal() {
      this.newuser.name = "";
      this.newuser.password = "";
      this.newuser.email = "test@mail.com";
      this.newuser.username = "";
      this.newuser.cellphone = "5511223344";
    },
    sendRappiMenu(id) {
      let that = this
      axios.post("/rappi/store/" + id + "/sendmenu")
        .then(function (data) {
          console.log(data)
          that.makeToast(
            "Exito",
            "Menu enviado",
            "success"
          );
        })
        .catch(function (err) {
          console.log(err);
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    newSucursal(event) {
      event.preventDefault();
      let that = this;
      axios
        .post("/user", this.newuser)
        .then(function (data) {
          that.makeToast(
            "Exito",
            data.response.username + " creado",
            "success"
          );
          that.cleanNewSucursal();
          that.getAllRappiStores();
          that.search = data.response.username;
        })
        .catch(function (err) {
          console.log(err.request.response);
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    getAllRappiStores() {
      let that = this;
      axios
        .get("/rappi/stores")
        .then(function (response) {
          that.storesTable = response.data.sort((a, b) => { return a.name.localeCompare(b.name) }).map(function (str) {
            let init = str
            let menuText = ""
            that.rappiMenusOptions.find(function (e) {
              if (e.id == init.rappi_menu_id) {
                menuText = e.label
                return true
              }
              return false
            })
            init.menu = menuText
            return init
          })
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    makeToast(title, content, variant) {
      console.log("MAKING TOAST")
      this.$bvModal.msgBoxOk(content, {
        title: title,
        size: 'sm',
        buttonSize: 'sm',
        okVariant: variant,
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true
      })
    },
  },
};
</script>
