<template>
  <div>

    <b-navbar toggleable="md" small type="dark" variant="dark">
      <b-navbar-brand @click="$router.push('/dashboard')"><img src="@/assets/img/logo_white.png"
          style="max-height: 5vh;" /></b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item @click="$router.push('/sucursales')">Sucursales</b-nav-item>
          <b-nav-item-dropdown text="Catálogos">
            <b-dropdown-group header="Productos vendibles">
              <b-dropdown-item @click="$router.push('/groups')"> Grupos</b-dropdown-item>
              <b-dropdown-item @click="$router.push('/products')">Productos</b-dropdown-item>
              <b-dropdown-item @click="$router.push('/personalizations')">Personalizadores</b-dropdown-item>
            </b-dropdown-group>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-group header="Generales">

              <b-dropdown-item @click="$router.push('/preparationareas')"> Áreas de Preparación</b-dropdown-item>
              <b-dropdown-item @click="$router.push('/discounts')">Descuentos</b-dropdown-item>
              <b-dropdown-item @click="$router.push('/loyaltyprograms')">Programas de Lealtad</b-dropdown-item>
              <b-dropdown-item @click="$router.push('/paymentmethods')">Formas de pago</b-dropdown-item>
              <b-dropdown-item @click="$router.push('/waiters')"> Meseros</b-dropdown-item>
            </b-dropdown-group>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-group header="Insumos y almacén">
              <b-dropdown-item @click="$router.push('/supplycategories')">Categorias de insumos</b-dropdown-item>
              <b-dropdown-item @click="$router.push('/supplies')">Insumos</b-dropdown-item>
              <b-dropdown-item @click="$router.push('/supply/lists')">Listas de Insumos</b-dropdown-item>
            </b-dropdown-group>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown text="Plataformas">
            <b-dropdown-header>Uber</b-dropdown-header>
            <b-dropdown-item @click="$router.push('/uber')">Creador de Menus</b-dropdown-item>
            <b-dropdown-item @click="$router.push('/uber/menu/viewer')">Visor de Menus</b-dropdown-item>
            <b-dropdown-header>Rappi</b-dropdown-header>
            <b-dropdown-item @click="$router.push('/rappi/stores')">Sucursales/Stores</b-dropdown-item>
            <b-dropdown-item @click="$router.push('/rappi/menu')">Creador de Menus</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown text="Reportes">
            <b-dropdown-item @click="$router.push('/dashboard')">Resumen General</b-dropdown-item>
            <b-dropdown-item @click="$router.push('/report/daily')">Venta Diaria</b-dropdown-item>
            <b-dropdown-item @click="$router.push('/report/daily/area')">Venta Diaria Por Área</b-dropdown-item>
            <b-dropdown-item @click="$router.push('/report/popularidad')">Venta Por
              Articulo/Popularidad</b-dropdown-item>
            <b-dropdown-item @click="$router.push('/ticket/query')">Buscar Cuentas</b-dropdown-item>
            <b-dropdown-item @click="$router.push('/report/date')">Mas Reportes</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown text="Inventarios">
            <b-dropdown-item @click="$router.push('/compras')">Compras</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown text="Tickets">
            <b-dropdown-item @click="$router.push('/ticket/query')">Buscar</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown text="Usuarios">
            <b-dropdown-item @click="$router.push('/userGroups')">Grupos</b-dropdown-item>
            <b-dropdown-item @click="$router.push('/signup')">Usuarios</b-dropdown-item>

          </b-nav-item-dropdown>
          <b-nav-item-dropdown text="Facturacion">
            <b-dropdown-item @click="$router.push('/facturas')">Mis Facturas</b-dropdown-item>
            <b-dropdown-item @click="$router.push('/factura')">Nueva Factura</b-dropdown-item>
            <b-dropdown-item @click="$router.push('/facturacion/clientes')">Clientes</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item @click="$router.push('/loyaltyprograms')">Lealtad</b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item @click="$router.push('/selecttenant?override=true')">Espacio: {{ tenant }}</b-nav-item>
          <b-nav-item-dropdown text="Mi usuario" right>
            <b-dropdown-item-button @click="logout">Salir</b-dropdown-item-button>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "Nav",
  data: () => {
    return {
    };
  },
  computed: {
    tenant() {
      return this.$store.getters["server/tenant"];
    },
    actions() {
      return this.$store.getters["auth/getActions"];
    }
  },
  mounted() {
    console.log("rrn:local:tickets:cancelTicket")
    console.log(this.hasPermission("rrn:local:tickets:cancelTicket"))
    console.log("rrn:local:tickets:cancelTicke")
    console.log(this.hasPermission("rrn:local:tickets:cancelTicke"))
  },
  methods: {
    hasPermission(rrn){
      return this.actions.includes(rrn)
    },
    logout() {
      localStorage.removeItem("user-token");
      localStorage.removeItem("user-token-time");
      axios.defaults.headers.common["Authorization"] = "Bearer ";
      this.$router.push("/").catch();
    },
    makeToast(title, content, variant) {
      this.$bvToast.toast(content, {
        title,
        variant,
        appendToast: true,
      });
    },
  },
};
</script>