<template>
    <LineChartGenerator v-if="show" :chart-options="chartOptions" :chart-data="{ labels, datasets }" :chart-id="chartId"
        :dataset-id-key="datasetIdKey" :css-classes="cssClasses" :styles="styles" :width="width" :height="height" />
</template>
<script>
import { Line as LineChartGenerator } from 'vue-chartjs/legacy'
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    CategoryScale,
    PointElement
} from 'chart.js'
ChartJS.register(
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    CategoryScale,
    PointElement
)
export default {
    name: 'LineChart',
    components: {
        LineChartGenerator
    },
    methods:{
    },
    props: {
        datasets: {
            type: Array,
            default: () => []
        },
        labels: {
            type: Array,
            default: () => []
        },
        chartId: {
            type: String,
            default: 'line-chart'
        },
        datasetIdKey: {
            type: String,
            default: 'label'
        },
        width: {
            type: Number,
            default: 400
        },
        height: {
            type: Number,
            default: 400
        },
        cssClasses: {
            default: '',
            type: String
        },
        styles: {
            type: Object,
            default: () => { }
        },
        plugins: {
            type: Array,
            default: () => []
        },
        options: {
            type: Object,
            default: () => ({
                legend: {
                    display: true
                },
                responsive: true,
                maintainAspectRatio: false
            })
        },
        show:{
            type:Boolean,
            default:true
        }
    },
    data() {
        return {
            chartOptions: {
                legend: {
                    display: true
                },
                normalized: true,
                responsive: true,
                spanGaps: true,
                maintainAspectRatio: false,
                animation: {
                    duration: 0 // general animation time
                },
                hover: {
                    animationDuration: 0 // duration of animations when hovering an item
                },
                responsiveAnimationDuration: 0, // animation duration after a resize
                elements: {
                    line: {
                        tension: .4 // disables bezier curves
                    }
                }
            }
        }
    }
}
</script>