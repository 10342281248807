<template>
    <Doughnut v-if="show" :chart-options="chartOptions" :chart-data="{datasets,labels}" :chart-id="chartId" :dataset-id-key="datasetIdKey"
        :css-classes="cssClasses" :styles="styles" :width="width" :height="height" />
</template>
<script>
import { Doughnut } from 'vue-chartjs/legacy'
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    CategoryScale
} from 'chart.js'
ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)
export default {
    name: 'DoughnutChart',
    components: {
        Doughnut
    },
    props: {
        datasets:{
            type:Array,
            default:()=>[]
        },
        labels:{
            type:Array,
            default:()=>[]
        },
        chartId: {
            type: String,
            default: 'doughnut-chart'
        },
        datasetIdKey: {
            type: String,
            default: 'label'
        },
        width: {
            type: Number,
            default: 400
        },
        height: {
            type: Number,
            default: 400
        },
        cssClasses: {
            default: '',
            type: String
        },
        styles: {
            type: Object,
            default: () => { }
        },show:{
            type:Boolean,
            default:true
        }
    },
    data() {
        return {
            chartData: {
                labels: ["Babol", "Cabanatuan", "Daegu", "Jerusalem"],
                datasets: [{
                    borderWidth: 1,
                    borderColor: [
                        'rgba(255,99,132,1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)'
                    ],
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                    ],
                    data: [1000, 500, 1500, 1000]
                }]
            },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                animation: {
                    duration: 0 // general animation time
                },
                hover: {
                    animationDuration: 0 // duration of animations when hovering an item
                },
                responsiveAnimationDuration: 0, // animation duration after a resize
                elements: {
                    line: {
                        tension: 0 // disables bezier curves
                    }
                }
            }
        }
    }
}
</script>