<template>
  <div>
    <Nav></Nav>
    <b-container>
      <b-row>
        <b-col>
          <h1 v-if="actualSupply.id > 0">
            Categoria de Insumos
            {{ this.actualSupply.id + " " + this.actualSupply.name }}
          </h1>
          <h1 v-else>Crear Grupo</h1>
        </b-col>
      </b-row>
      <b-card>
        <b-form>
          <b-row>
            <b-col xl="9" md="12">
              <b-form-group label="Nombre:">
                <b-form-input v-model="actualSupply.name" type="text" required></b-form-input>
              </b-form-group>
            </b-col>
            <b-col xl="3" md="12">
              <b-form-group label="Status:">
                <b-form-checkbox v-model="actualSupply.status"  :value="true"
                  :unchecked-value="false">
                  Activo
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-button v-if="actualSupply.id > 0" block variant="success" @click="updateProduct">Guardar</b-button>
            <b-button v-else block variant="success" @click="updateProduct">Crear</b-button>
          </b-row>
        </b-form>
      </b-card>
    </b-container>
  </div>
</template>
<script>
import Nav from "../components/Nav.vue";
import axios from "axios";
export default {
  name: "Supplycategory",
  components: { Nav },
  data() {
    return {
      statusoptions: [
        { value: "ACTIVE", text: "ACTIVO" },
        { value: "INACTIVE", text: "INACTIVO" },
      ],
      show: false,
      actualSupply: {
        id: 0,
        name: "",
        company_id: null,
        status: true,
        create_uid: null,
        create_date: null,
        write_date: null
      },
    };
  },
  beforeRouteUpdate(to, from) {
    console.log(to);
    console.log(from);
    this.initData();
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      console.log("creando");
      console.log(this.$route.params.id);
      if (this.$route.params.id > 0) {
        console.log("producto no nuevo");
        this.actualSupply.id = this.$route.params.id;
        this.getSupply();
      }
      console.log("termina creacion");
    },

    getSupply() {
      let that = this;
      axios
        .get("/supplycategory/" + this.actualSupply.id)
        .then(function (response) {
          that.actualSupply = response.data;
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    updateProduct() {
      let that = this;
      if (this.actualSupply.id > 0) {
        axios
          .put("/supplycategory/" + this.actualSupply.id, this.actualSupply)
          .then(function (response) {
            that.actualSupply = response.data;
            that.makeToast("Exito", "exito al guardar", "success");
            that.getSupply();
            that.$router.push({
              name: "Supplycategories"
            });
          })
          .catch(function (err) {
            that.makeToast("Error", err.request.response, "danger");
          });
      } else {
        this.actualSupply.status = true
        axios
          .post("/supplycategory", this.actualSupply)
          .then(function (response) {
            that.makeToast("Exito", "exito al crear, redirigiendo", "success");
            console.log(response.data);
            that.$router.push({
              name: "Supplycategories"
            });
          })
          .catch(function (err) {
            that.makeToast("Error", err.request.response, "danger");
          });
      }
    },

    makeToast(title, content, variant) {
      this.$bvToast.toast(content, {
        title,
        variant,
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
  },
};
</script>
