import axios from 'axios'
const state = {
    server: {
        tenant: localStorage.getItem('tenant') || ''
    },
};

const getters = {
    tenant: (state) => state.server.tenant,
};

const actions = {
    changeTenantData({ commit }, tenant) {
        localStorage.setItem('tenant', tenant)
        if (process.env.NODE_ENV === 'production') {
            axios.defaults.baseURL = 'https://pos.tizon.pereztecnologia.com/' + localStorage.getItem('tenant')
        } else {
            axios.defaults.baseURL = process.env.VUE_APP_SERVER_DOMAIN+localStorage.getItem('tenant')
        }
        commit('CH_TENANT_DATA', tenant)
    },
};

const mutations = {
    CH_TENANT_DATA(state, tenant) {
        state.server.tenant = tenant
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};