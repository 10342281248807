<template>
  <div>
    <Nav></Nav>
    <b-container>
      <b-row>
        <b-col>
          <h1 v-if="actualPrep.id > 0">
            Grupo:
            {{ this.actualPrep.id + " " + this.actualPrep.name }}
          </h1>
          <h1 v-else>Crear Area Preparacion</h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-card>
            <b-row>
              <b-col md="6" sm="12">
                <b-form-group label="Nombre:">
                  <b-form-input
                    v-model="actualPrep.name"
                    type="text"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="6" sm="12">
                <b-form-group label="Descripcion:">
                  <b-form-input
                    v-model="actualPrep.description"
                    type="text"
                    required
                  ></b-form-input>updatePrep
                </b-form-group>
              </b-col>
              <b-col md="6" sm="12">
                <b-form-group label="Activo:">
                  <b-form-checkbox v-model="actualPrep.status">
                    Activo
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mt-3">
              <b-button
                v-if="actualPrep.id > 0"
                block
                variant="success"
                @click="updatePrep"
                >Guardar</b-button
              >
              <b-button v-else block variant="success" @click="createPrep"
                >Crear</b-button
              >
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import Nav from "../components/Nav.vue";
import axios from "axios";
export default {
  name: "PreparationArea",
  components: { Nav },
  data() {
    return {
      actualPrep: {
        id: 0,
        name: "",
        description: "",
        status: true,
      },
    };
  },
  mounted() {
    if (this.$route.params.id > 0) {
      this.actualPrep.id = this.$route.params.id;
      console.log(this.actualPrep.id);
      this.getGroup();
    }
  },
  methods: {
    getGroup() {
      console.log(this.actualPrep);
      let that = this;
      axios
        .get("/preparationarea/" + this.actualPrep.id)
        .then(function (response) {
          that.actualPrep = response.data;
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    updatePrep() {
      let that = this;
      axios
        .put("/preparationarea/" + this.actualPrep.id, this.actualPrep)
        .then(function (response) {
          that.actualPrep = response.data;
          that.makeToast("Exito", "exito al guardar", "success");
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    createPrep() {
      let that = this;
      axios
        .post("/preparationarea", this.actualPrep)
        .then(function (response) {
          that.actualPrep = response.data;
          console.log(response.data)
          that.makeToast("Exito", "exito al crear", "success");
          that.$router.push("/preparationarea/" + response.data.id);
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    makeToast(title, content, variant) {
      this.$bvToast.toast(content, {
        title,
        variant,
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
  },
};
</script>
