<template>
  <div>
    <Nav></Nav>
    <b-container fluid="xl">
      <b-row>
        <b-col>
          <h1>Alta de Meseros</h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button variant="default" @click="$router.push('/waiter')">Crear Mesero</b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Buscar:">
            <b-form-input type="search" v-model="search" @keyup="page=1" debounce="500"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table
          stacked="sm"
            :filter="search"
            striped
            hover
            :items="productsTable"
            :fields="tableFields"
            responsive
            :per-page="byPage"
            :current-page="page"
          >
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>

            <template #cell(buttons)="data">
              <b-button-group vertical>
                <b-button size="sm" variant="warning" @click="$router.push('/waiter/' + data.item.id)"
                  >Editar</b-button
                >
              </b-button-group>
            </template>
           
            <template #cell(status)="data"> 
                <b-badge pill v-if="data.item.status" variant="success">ACTIVO</b-badge>
                <b-badge pill v-else-if="!data.item.status" variant="danger">INACTIVO</b-badge>
                
            </template>
            <template #cell(image)="data"> 
              <img :src="axios.defaults.baseURL + '/' +data.item.image">
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-pagination
            v-model="page"
            :total-rows="productsTable.length"
            :per-page="byPage"
            aria-controls="my-table"
    ></b-pagination>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import Nav from "../components/Nav.vue";
import axios from "axios";
export default {
  name: "Waiters",
  components: { Nav },
  data() {
    return {
      byPage:20,
      page:1,
      productsTable: [],
      search: "",
      subgroups:[],
      groups:[],
      tableFields: [
        { key: "id", label: "ID", sortable: true, sortDirection: "desc" },
        { key: "name", label: "Nombre" },
        { key: "status", label: "Status", sortable: true },
        { key: "buttons", sortable: true },
      ],
    };
  },
  mounted() {
    this.getAllUsers();
  },
  methods: {
    
    getAllUsers() {
      let that = this;
      axios
        .get("/waiters")
        .then(function (response) {
          that.productsTable = response.data;
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    
    makeToast(title, content, variant) {
      this.$bvToast.toast(content, {
        title,
        variant,
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
  },
};
</script>
