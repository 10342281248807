<template>
  <div>
    <Nav></Nav>
    <b-container fluid="xl">
      <b-row>
        <b-col>
          <h1> Alta de Productos</h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button variant="default" @click="$router.push('/product')">Crear Producto</b-button>
        </b-col>
      </b-row>
      <b-row class="my-3">
        <b-col>
          <b-card>
            <b-row>
              <b-col>
                <h3>Filtros:</h3>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group label="Buscar:">
                  <b-form-input type="search" v-model="search" @keyup="page = 1" debounce="500"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-checkbox v-model="activeProductFilter" :value="true"
                  @input="getAllUsers"
                  :unchecked-value="false">
                  Solo Productos Activos
                </b-form-checkbox>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          Hay {{ productsTable.length }} registros
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table stacked="sm" :filter="search" striped hover :items="productsTable" :fields="tableFields"
            responsive="md" :per-page="byPage" :current-page="page">
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template #cell(buttons)="data">
              <b-button-group vertical>
                <b-button size="sm" variant="warning" :href="'/product/' + data.item.id">Editar</b-button>

              </b-button-group>
            </template>
            <template #cell(group)="data">
              {{ searchGroup(data.item.group).name }}
            </template>
            <template #cell(subgroup)="data">
              {{ searchSubgroup(data.item.subgroup).name }}
            </template>
            <template #cell(status)="data">
              <b-badge pill v-if="data.item.status == 'ACTIVE'" variant="success">ACTIVO</b-badge>
              <b-badge pill v-else-if="data.item.status == 'INACTIVE'" variant="danger">INACTIVO</b-badge>
            </template>
            <template #cell(image)="data">
              <b-media v-if="data.item.image != ''" no-body class="align-items-center">
                <b-img class="avatar" rounded="true" height="3em" alt="Image placeholder"
                  :src="axios.defaults.baseURL + '/' + data.item.image" />
              </b-media>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-pagination v-model="page" :total-rows="productsTable.length" :per-page="byPage"
            aria-controls="my-table"></b-pagination>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import Nav from "../components/Nav.vue";
import axios from "axios";
export default {
  name: "Products",
  components: { Nav },
  data() {
    return {
      byPage: 20,
      page: 1,
      productsTable: [],
      search: "",
      activeProductFilter:false,
      subgroups: [],
      groups: [],
      tableFields: [
        { key: "id", label: "ID", sortable: true, sortDirection: "desc", class: "align-middle" },
        { key: "clave", label: "Clave", sortable: true, class: "align-middle" },
        { key: "image", label: "img" },
        { key: "ndescription", label: "Nombre", sortable: true, class: "align-middle" },
        // { key: "group", label: "Grupo", sortable: true, class:"align-middle" },
        // { key: "subgroup", label: "Subgrupo", sortable: true , class:"align-middle"},
        // { key: "short_name", label: "Nombre Cto", sortable: true , class:"align-middle"},
        { key: "status", label: "Status", sortable: true, class: "align-middle" },
        { key: "buttons", sortable: true, class: "align-middle" },
      ],
    };
  },
  activated() {
    this.getAllUsers();
    // this.getProductGroups()
    // this.getProductSubgroups()
  },
  created() {
    this.getAllUsers();
    // this.getProductGroups()
    // this.getProductSubgroups()
  },
  methods: {
    cleanNewUser() {
      this.newuser.name = "";
      this.newuser.password = "";
      this.newuser.email = "test@mail.com";
      this.newuser.username = "";
      this.newuser.cellphone = "5511223344";
    },
    newUser(event) {
      event.preventDefault();
      let that = this;
      axios
        .post("/user", this.newuser)
        .then(function (data) {
          that.makeToast(
            "Exito",
            data.response.username + " creado",
            "success"
          );
          that.cleanNewUser();
          that.getAllUsers();
          that.search = data.response.username;
        })
        .catch(function (err) {
          console.log(err.request.response);
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    getAllUsers() {
      let that = this;
      axios
        .get("/productswithoutprices")
        .then(function (response) {
          that.productsTable = response.data.filter(function(x){
            if(that.activeProductFilter){
              return x.status=="ACTIVE"
            }else{
              return true
            }
          })
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    searchGroup(id) {
      return (
        this.groups.find((element) => element.id == id) || { name: "error" }
      );
    },
    getProductGroups() {
      let that = this;
      axios
        .get("/groups")
        .then(function (response) {
          that.groups = response.data;

        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    searchSubgroup(id) {
      return (
        this.subgroups.find((element) => element.id == id) || { name: "error" }
      );
    },
    getProductSubgroups() {
      let that = this;
      axios
        .get("/subgroups")
        .then(function (response) {
          that.subgroups = response.data;
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    makeToast(title, content, variant) {
      this.$bvToast.toast(content, {
        title,
        variant,
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
  },
};
</script>
