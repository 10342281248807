<template>
  <div>

    <b-navbar type="dark" variant="dark">
      <b-navbar-brand>
        <img src="@/assets/img/logo_white.png" style="max-height: 5vh;" />
      </b-navbar-brand>
    </b-navbar>
    <b-container class="mt-2">
      <b-row>
        <b-col>
          <h1>Bienvenido a Rigada Punto de Venta</h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Selecciona el negocio que quieres administrar">
            <b-form-radio v-for="n in tenants" v-model="selectedTenant" name="some-radios" v-bind:key="n.id"
              :value="n.tenant_name">{{ n.name }}</b-form-radio>
          </b-form-group>
          <b-form-group>
            <b-form-checkbox v-model="rememberTenant" name="checkbox-1" value="true" unchecked-value="false">
              Recordar mi seleccion para no regresar a esta pantalla en proximas visitas?
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button :disabled="selectedTenant.length<1" variant="success" @click="selectTenant()">Entrar</b-button>
        </b-col>
      </b-row>
    </b-container>
  </div>

</template>

<script>
import axios from "axios";
export default {
  name: "SelectTenant",
  components: {},
  data() {
    return {
      tenants: [],
      selectedTenant: null,
      rememberTenant: null
    };
  },
  mounted() {
    this.selectedTenant = this.$store.getters["server/tenant"]
    this.rememberTenant = localStorage.getItem('rememberTenant')
    if (this.rememberTenant == "true" && this.selectedTenant.length > 0 && this.$route.query.override!="true") {
      this.$store.dispatch("server/changeTenantData", this.selectedTenant);
      this.$router.push("/dashboard").catch();
    }
    this.getMyTenants()
  },
  async created() {
  },
  methods: {
    makeToast(title, content, variant) {
      this.$bvToast.toast(content, {
        title,
        variant,
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
    selectTenant() {
      if(this.selectedTenant.length<1){
        this.$bvModal.msgBoxOk('Debes seleccionar al menos un negocio que desees operar', {
          title: 'Error',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true
        })
        return  
      }
      localStorage.setItem('rememberTenant',this.rememberTenant)
      axios.defaults.baseURL = this.$backDomain + localStorage.getItem('tenant')
      this.$store.dispatch("server/changeTenantData", this.selectedTenant);
      this.$router.push("/dashboard").catch();

    },
    getMyTenants() {
      let that = this
      axios.get(this.$backDomain + "user/tenants")
        .then(function (res) {
          that.tenants = res.data
          if(res.data.length==1){
            //that.selectedTenant=res.data[0].tenant_name
            that.selectTenant()
          }
        })
        .catch(function (err) {
          console.error(err)
          console.log(err.response)
        })
    }
  },
};
</script>