<template>
    <div>
        <Nav> </Nav>
        <b-container>
            <b-row>
                <b-col>
                    <h1 v-if="actualDiscount.id > 0">
                    Descuento 
                 {{ this.actualDiscount.id + " "+ this.actualDiscount.name}}</h1>
                 <h1 v-else> Crear Descuento </h1>
                </b-col>
            </b-row>

            <b-card>
                <b-form>
                    <b-row>
                        <b-col md="6" sm="12">
                            <b-form-group label="Nombre:">
                                <b-form-input v-model="actualDiscount.name"
                                type="text"
                                required>
                                </b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col md="6" sm="12">
                            <b-form-group label="Descripción:">
                                <b-form-input v-model="actualDiscount.description"
                                type="text"
                                required>
                                </b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col md="6" sm="12">
                            <b-form-group label="Porcentaje de descuento:">
                                <b-form-input v-model.number="actualDiscount.percentage_to_discount"
                                type="number"
                                required>
                                </b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col xl="6" md="12">
                            <b-form-group label="Status">
                                <b-form-checkbox v-model="actualDiscount.status">
                                    Activo
                            </b-form-checkbox>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row class="mt-3">
                        <b-button v-if="actualDiscount.id > 0"
                            block
                            variant="success"
                            @click="updateDiscount">
                            Guardar
                        </b-button>
                        <b-button v-else block variant="success" @click="createDiscount"> Crear </b-button>
                    </b-row>
                </b-form>
            </b-card>
        </b-container>
    </div>
</template>


<script>
import Nav from "../components/Nav.vue";
import axios from "axios";
export default {
  name: "Discount",
  components: { Nav },
  data() {
    return {
      actualDiscount: {
        id: 0,
        name:"",
        description:"",
        percentage_to_discount:0,
        status: true,
      },
      
    };
  },
  mounted() {
    if (this.$route.params.id > 0) {
      this.actualDiscount.id = this.$route.params.id;
      console.log(this.actualDiscount.id);
      this.getDiscount();
    }
  },
  methods: {
    getDiscount() {
      console.log(this.actualDiscount);
      let that = this;
      axios
        .get("/discount/" + this.actualDiscount.id)
        .then(function (response) {
          that.actualDiscount = response.data;
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    updateDiscount() {
      let that = this;
      axios
        .put("/discount/" + this.actualDiscount.id, this.actualDiscount)
        .then(function (response) {
          that.actualDiscount = response.data;
          that.makeToast("Exito", "exito al guardar", "success");
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    createDiscount() {
      let that = this;
      axios
        .post("/discount", this.actualDiscount)
        .then(function (response) {
          that.actualDiscount = response.data;
          console.log(response.data)
          that.makeToast("Exito", "exito al crear", "success");
          that.$router.push("/discount/" + response.data.id);
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    makeToast(title, content, variant) {
      this.$bvToast.toast(content, {
        title,
        variant,
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
  }
  }
</script>