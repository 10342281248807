<template>
  <div>
    <Nav></Nav>
    <b-container fluid>

      <b-row>
        <b-col>
          <h1> Búsqueda de cuentas</h1>
        </b-col>
      </b-row>
      <ReportSearchBar :emitOnStart="false" :emitOnChange="false" @search="recvDoc"></ReportSearchBar>
      <b-card border-variant="primary" class="my-1">
        <b-overlay :show="loadingTicketsList" rounded="sm">
          <b-row class="my-3">
            <b-col>
              <p>Hay {{ tickets.length }} cuentas</p>
            </b-col>
          </b-row>
          <b-row v-if="tickets.length > 0">
            <b-col>
              <b-input-group prepend="Buscar:">
                <b-form-input debounce="500" v-model="filterTable"></b-form-input>
              </b-input-group>
            </b-col>
          </b-row>
          <b-row  v-if="tickets.length > 0">
            <b-col>
              <b-table :filter="filterTable"  :per-page="byPage" :current-page="page" :busy="activeTicketsLoading" ref="tableCuentas" id="tableCuentas"
                selectable :responsive="true" select-mode="single" :items="tickets" :fields="activeTicketsFields"
                @row-clicked="getActiveTicket" small sticky-header="70vh" selected-variant="primary">
                <template #cell(cenceled)="data">
                  <b-icon v-if="data.item.canceled" icon="dash-circle-fill" variant="danger"></b-icon>
                </template>
                <template #cell(total_with_discount)="data">
                  ${{ data.item.total_with_discount }}
                </template>
              </b-table>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-pagination v-model="page" :total-rows="tickets.length" :per-page="byPage"
                aria-controls="my-table"></b-pagination>
            </b-col>
          </b-row>
        </b-overlay>
      </b-card>

    </b-container>
    <CuentaDetalle @changeTicketId="closeAccount()" :ticket_id="activeTicketId"></CuentaDetalle>
  </div>
</template>
<script>
import axios from "axios";
import Nav from "../components/Nav.vue";
import CuentaDetalle from "../components/CuentaDetalle.vue";
import ReportSearchBar from "./reports/searchBar/ReportSearchBar.vue";
import moment from 'moment'
export default {
  name: "CuentasHistoricas",
  components: {
    Nav, CuentaDetalle, ReportSearchBar
  },

  data() {
    return {
      byPage: 30,
      page: 1,
      filterTable: "",
      sucursalOptions: [],
      queryDatesTickets: {
        initial_date: "",
        end_date: "",
        sucursal_id: [],
      },
      helpKeys: false,
      log: [],
      logFields: [
        { key: "action_id", label: "ID" },
        { key: "action_date", label: "Fecha" },
        { key: "description", label: "Descripcion" },
      ],
      loadingCuenta: false,
      loadingTicketsList: false,
      activeTicketsLoading: false,
      lastInput: "",
      tickets: [],
      paymentFields: [
        { key: "id", label: "ID" },
        { key: "payment_method.name", label: "Metodo de pago" },
        { key: "payment_amount", label: "Monto" },
        { key: "tip_amount", label: "Propina" },
        { key: "status", label: "Status" },
        { key: "buttons", label: "" }
      ],
      activeTicketsFields: [
        { key: "numticket", label: "Folio", sortable: true },
        { key: "restaurant_area_id", label: "Area", sortable: true },
        { key: "table_no", label: "Mesa", sortable: true },
        { key: "cenceled", label: "Canc.", sortable: true },
        { key: "total_with_discount", label: "Tot.", sortable: true },
        { key: "date_open", label: "Fecha", sortable: true },
        { key: "sucursal_id", label: "Sucursal", sortable: true },
      ],
      actualCuentaFields: [
        { key: "movement", label: "Mov" },
        { key: "quantity", label: "Cant" },
        { key: "product_code", label: "Clave" },
        { key: "cancelled", label: "Canc." },
        { key: "product.ndescription", label: "Descripcion" },
        { key: "discount_percentage", label: "Descuento" },
        { key: "price", label: "Precio" },
        { key: "total_with_discount", label: "Total" },
      ],
      actualCuenta: {
        ticket_products: [],
        restaurant_area: {
          name: "",
        },
        discount_type: {
          name: "",
        },
      },
      activeTicketId: 0,
    };
  },
  computed: {
  },
  watch: {},
  mounted() {
    this.querySucursals()
  },
  created() {

  },
  methods: {
    recvDoc(p) {
      this.queryDatesTickets.initial_date = moment(p.initDate).format("YYYY-MM-DD")
      this.queryDatesTickets.end_date = moment(p.endDate).format("YYYY-MM-DD")
      this.queryDatesTickets.sucursal_id = p.sucursales
      this.getTicketsByDate()
    },
    querySucursals() {
      let that = this
      axios
        .get("/sucursals")
        .then(function (response) {
          let finalSucs = []
          response.data.forEach(element => {
            finalSucs.push({ value: element.id, text: element.id + " - " + element.nombre, nm: element.nombre })
          })
          finalSucs = finalSucs.sort(function (a, b) {
            return a.nm.localeCompare(b.nm);
          });
          that.sucursalOptions = finalSucs
        })
        .catch(function (r) {
          console.log(r)
          that.makeToast("danger", "Error", r.response.data);
        });
    },
    getTicketsByDate() {
      if (
        this.queryDatesTickets.initial_date.length < 1 ||
        this.queryDatesTickets.end_date.length < 1
      ) {
        this.loadingTicketsList = false;
        this.makeToast(
          "danger",
          "FECHAS INCORRECTAS",
          "DEBES SELECCIONAR FECHAS CORRECTAS"
        );
        return;
      }
      this.loadingTicketsList = true;
      let that = this;
      axios
        .post("/tickets/date", this.queryDatesTickets)
        .then(function (response) {
          if (response.data != null) {
            that.tickets = response.data;
          }
          that.page=1
          that.loadingTicketsList = false;
        })
        .catch(function (r) {
          that.loadingTicketsList = false;
          that.makeToast("danger", "Error", r.response.data);
          that.loadingTicketsList = false;
        });
    },
    closeAccount() {
      this.activeTicketId = 0;
      this.$refs.tableCuentas.clearSelected();
      console.log("closeAccount event emitted")
    },
    getActiveTicket: function (row) {
      this.activeTicketId = row.sid;
    },
    makeToast(variant = null, title = "", text = "") {
      this.$bvToast.toast(text, {
        title: title,
        variant: variant,
        solid: true,
        appendToast: true,
      });
    },
  },
};
</script>
