<template>
  <div>
    <Nav></Nav>
    <b-container>
      <b-row>
        <b-col>
          <h1 v-if="actualProduct.id > 0">
            Personalizador
            {{ this.actualProduct.id + " " + this.actualProduct.name }}
          </h1>
          <h1 v-else>Crear Personalizador</h1>
        </b-col>
      </b-row>
      <b-row>
        <b-jumbotron v-if="actualProduct.id > 0" class="p-3 mx-auto">
          <b-col>
            <img
              :src="axios.defaults.baseURL + '/' + this.actualProduct.image"
            />
            <b-button
              class="mx-5"
              
              @click="show = !show"
              >Editar Imagen</b-button
            >
          </b-col>
        </b-jumbotron>
        <my-upload
          field="image"
          @crop-success="cropSuccess"
          @crop-upload-success="cropUploadSuccess"
          @crop-upload-fail="cropUploadFail"
          v-model="show"
          lang="es-MX"
          lang-type="es-MX"
          :no-circle="true"
          :width="128"
          :height="128"
          :url="
            axios.defaults.baseURL +
            '/personalization/' +
            this.actualProduct.id +
            '/image'
          "
          :params="imgparams"
          :headers="imgheaders"
          img-format="png"
        ></my-upload>
      </b-row>
      <b-card>
        <b-form>
          <b-row>
            <b-col md="6" sm="12">
              <b-form-group label="Nombre:">
                <b-form-input
                  v-model="actualProduct.name"
                  type="text"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6" sm="12">
              <b-form-group label="Nombre corto (boton captura tactil):">
                <b-form-input
                  v-model="actualProduct.short_name"
                  type="text"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col xl="6" md="12">
              <b-form-group label="Status">
                  <b-form-checkbox v-model="actualProduct.status">
                    Activo
                  </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="mt-3">
            <b-button
              v-if="actualProduct.id > 0"
              block
              variant="success"
              @click="updateProduct"
              >Guardar</b-button
            >
            <b-button v-else block variant="success" @click="updateProduct">Crear</b-button>
          </b-row>
        </b-form>
      </b-card>
    </b-container>
  </div>
</template>
<script>
import Nav from "../components/Nav.vue";
import axios from "axios";
import myUpload from "vue-image-crop-upload/upload-2.vue";
export default {
  name: "Personalization",
  components: { Nav, myUpload },
  data() {
    return {
      imgheaders: {
        Authorization: "",
      },
      imgparams: {},
      show: false,
      actualProduct: {
        id: 0,
        name:"",
        short_name:"",
        image: "",
        image_mini: "",
        status: true,
       
      },
      
    };
  },
  mounted() {
    this.imgheaders.Authorization =
      "Bearer " + localStorage.getItem("user-token");
    if (this.$route.params.id > 0) {
      this.actualProduct.id = this.$route.params.id;
      this.getProduct();
    }
  },
  methods: {
    cropSuccess(_, field) {
      console.log("-------- crop success --------");
      console.log(field);
    },

    cropUploadSuccess(jsonData, field) {
      console.log("-------- upload success --------");
      console.log(jsonData);
      console.log("field: " + field);
      this.getProduct();
    },

    cropUploadFail(status, field) {
      console.log("-------- upload fail --------");
      console.log(status);
      console.log("field: " + field);
    },

    getProduct() {
      let that = this;
      axios
        .get("/personalization/" + this.actualProduct.id)
        .then(function (response) {
          that.actualProduct = response.data;
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    updateProduct() {
      let that = this;
      if(this.actualProduct.id>0){
        axios
        .put("/personalization/" + this.actualProduct.id, this.actualProduct)
        .then(function (response) {
          that.actualProduct = response.data;
          that.makeToast("Exito", "exito al guardar", "success");
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
      }else{
        axios
        .post("/personalization", this.actualProduct)
        .then(function (response) {
          that.actualProduct = response.data;
          that.makeToast("Exito", "exito al crear", "success");
          that.$router.push("/personalization/"+response.data.id).catch();
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
      }
      
    },
    makeToast(title, content, variant) {
      this.$bvToast.toast(content, {
        title,
        variant,
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
  },
};
</script>
