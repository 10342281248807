<template>
    <div>
        <b-modal :hideHeaderClose="true"  no-close-on-esc no-close-on-backdrop @ok="finishClient" id="modal-createFacturacionCliente" size="xl" :title="isEdit ? 'Editar Cliente' : 'Crear Cliente'">
            <p>Introduce los datos para crear un nuevo cliente:</p>
            <b-row>
                <b-col>
                    <h3>Campos obligatorios</h3>
                </b-col>
            </b-row>
            <b-row>
                <b-col lg="4" md="12">
                    <b-form-group label="RFC">
                        <b-form-input v-model="clientData.rfc"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col lg="8" md="12">
                    <b-form-group label="Razon Social">
                        <b-form-input v-model="clientData.razon_social"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col lg="4" md="12">
                    <b-form-group label="Codigo Postal">
                        <b-form-input v-model="clientData.codpos"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col lg="8" md="12">
                    <b-form-group label="Regimen Fiscal">
                        <vu-select :reduce="(x) => x.id" v-model="clientData.regimen"
                            :options="regimenFiscalOptions"></vu-select>
                    </b-form-group>
                </b-col>
                <b-col lg="12" md="12">
                    <b-form-group label="Email">
                        <b-form-input v-model="clientData.email"></b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <h3>Campos no obligatorios pero sugeridos</h3>
                </b-col>
            </b-row>
            <b-row>
                <b-col lg="8" md="12">
                    <b-form-group label="Telefono">
                        <b-form-input v-model="clientData.telefono"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col lg="6" md="12">
                    <b-form-group label="Nombre">
                        <b-form-input v-model="clientData.nombre"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col lg="6" md="12">
                    <b-form-group label="Apellidos">
                        <b-form-input v-model="clientData.apellidos"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col lg="6" md="12">
                    <b-form-group label="Calle">
                        <b-form-input v-model="clientData.calle"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col lg="3" md="12">
                    <b-form-group label="No. ext">
                        <b-form-input v-model="clientData.numero_exterior"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col lg="3" md="12">
                    <b-form-group label="No. int">
                        <b-form-input v-model="clientData.numero_interior"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col lg="6" md="12">
                    <b-form-group label="Colonia">
                        <b-form-input v-model="clientData.colonia"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col lg="6" md="12">
                    <b-form-group label="Ciudad">
                        <b-form-input v-model="clientData.ciudad"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col lg="6" md="12">
                    <b-form-group label="Delegacion">
                        <b-form-input v-model="clientData.delegacion"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col lg="6" md="12">
                    <b-form-group label="Estado">
                        <b-form-input v-model="clientData.estado"></b-form-input>
                    </b-form-group>
                </b-col>



            </b-row>
        </b-modal>
    </div>
</template>
<script>
import axios from "axios";

export default {
    name: "CreateFacturacionCliente",
    props: ["sucursal_id"],
    data: () => {
        return {
            isEdit: false,
            clientToEdit: null,
            regimenFiscalOptions: [],
            clientData: {
                nombre: "",
                apellidos: "",
                email: "",
                email2: "",
                email3: "",
                telefono: "",
                razon_social: "",
                rfc: "",
                regimen: "",
                calle: "",
                numero_exterior: null,
                numero_interior: "",
                codpos: null,
                colonia: "",
                estado: "",
                ciudad: "",
                delegacion: ""
            }
        };
    },
    computed: {},
    mounted() {
        if (this.sucursal_id > 0) {
            this.getCatalogRegimen()
        }
    },
    watch: {
        sucursal_id(n) {
            if (n > 0) {
                this.getCatalogRegimen()
            }
        }
    },
    methods: {
        openModal() {
            this.$bvModal.show("modal-createFacturacionCliente")
        },
        closeModal() {
            this.$bvModal.hide("modal-createFacturacionCliente")
        },
        editClient(id) {
            this.isEdit = true
            this.clientToEdit = id
            this.openModal()
            this.cleanClient()
            this.getClient()
        },
        cleanClient() {
            this.clientData = {
                nombre: "",
                apellidos: "",
                email: "",
                email2: "",
                email3: "",
                telefono: "",
                razon_social: "",
                rfc: "",
                regimen: null,
                calle: "",
                numero_exterior: null,
                numero_interior: "",
                codpos: null,
                colonia: "",
                estado: "",
                ciudad: "",
                delegacion: ""
            }
        },
        createClient() {
            this.isEdit = false
            this.clientToEdit = null
            this.cleanClient()
            this.openModal()
        },
        getClient() {
            let that = this;
            axios
                .get("/company/1/sucursal/" + this.sucursal_id + "/factura/cliente/uid/" + this.clientToEdit)
                .then(function (response) {
                    //let uso = response.data.map((x) => { return { id: parseInt(x.key), label: x.key + " - " + x.name } })
                    that.clientData = response.data
                })
                .catch(function (err) {
                    that.makeToast("Error", err.request.response, "danger");
                });
        },
        finishClient(e) {
            e.preventDefault()
            let that=this
            if (this.isEdit) {
                axios
                    .put("/company/1/sucursal/" + this.sucursal_id + "/facturacion/cliente", this.clientData)
                    .then(function (response) {
                        that.makeToast(
                            "Exito",
                            " guardado",
                            "success"
                        );
                        that.$emit('updated',response.data.uid)
                        that.closeModal()
                    })
                    .catch(function (err) {
                        console.log(err.request.response)
                        that.makeToast("Error", err.request.response, "danger");
                    });
            } else {
                axios
                    .post("/company/1/sucursal/" + this.sucursal_id + "/facturacion/cliente", this.clientData)
                    .then(function (response) {
                        that.makeToast(
                            "Exito",
                            " creado",
                            "success"
                        );
                        that.$emit('updated',response.data.uid)
                        that.closeModal()
                    })
                    .catch(function (err) {
                        console.log(err.request.response)
                        that.makeToast("Error", err.request.response, "danger");
                    });
            }
        },
        getCatalogRegimen() {
            let that = this;
            axios
                .get("/company/1/sucursal/" + this.sucursal_id + "/sat/catalog/regimenfiscal")
                .then(function (response) {
                    let uso = response.data.map((x) => { return { id: x.key, label: x.key + " - " + x.name } })
                    that.regimenFiscalOptions = uso
                })
                .catch(function (err) {
                    that.makeToast("Error", err.request.response, "danger");
                });
        },
        makeToast(title, content, variant) {
            this.$bvToast.toast(content, {
                title,
                variant,
                appendToast: true,
            });
        },
    },
};
</script>