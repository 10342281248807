import Vue from 'vue'
const state = {
    socket: {
        isConnected: false,
        message: '',
        reconnectError: false
    },
    lastAction:{
        action:"",
        payload:{}
    }
};

const getters = {
    wsLastAction: state=>{return state.lastAction},
};

const actions = {
    newTicket(){
        console.log("New ticket created")
        this.dispatch("api/getActivetickets",{root:true})
    },
    updateTicket(){
        console.log("updateTicket")
        this.dispatch("api/getActivetickets",{root:true})
    },
    updateProductsTicket(){
        console.log("updateProductsTicket")
        this.dispatch("api/getActivetickets",{root:true})
    },
    addProductsTicket(){
        console.log("addProductsTicket")
        this.dispatch("api/getActivetickets",{root:true})
    },
    
};

const mutations = {
    SOCKET_ONOPEN(state, event) {
        console.log('SOCKET_ONOPEN');
        Vue.prototype.$socket = event.currentTarget;
        state.socket.isConnected = true;
    },

    SOCKET_ONCLOSE(state, event) {
        console.log('SOCKET_ONCLOSE');
        state.socket.isConnected = false;
        console.log(state, event);
    },

    SOCKET_ONERROR(state, event) {
        console.log('SOCKET_ONERROR');
        console.error(state, event);
    },

    // default handler called for all methods
    SOCKET_ONMESSAGE(state, message) {
        console.log("NEW WS MSJ")
        if(message.data instanceof Blob){
            console.log("Blob")
            console.log(message.data)
        }else{
            console.log("ws another");
            state.socket.message = message
            var decoded=JSON.parse(message.data)
            console.log(decoded.action)
            state.lastAction.action=decoded.action
            state.lastAction.payload=decoded.data
            switch (decoded.action) {
                case 'newTicket':
                    this.dispatch('newTicket')
                    break;
                case 'updateTicket':
                    this.dispatch('updateTicket')
                    break;
                case 'insertProductsTicket':
                    this.dispatch('updateProductsTicket')
                    break;
                case 'updateProductTicket':
                    this.dispatch('updateProductsTicket')
                    break;
                case 'cancelProductTicket':
                    this.dispatch('updateProductsTicket')
                    break;
                case 'ticketUpdate':
                    this.dispatch('updateProductsTicket')
                    this.dispatch('api/getActivetickets')
                    
                    break;
                case 'Identification':
                    this.dispatch('SOCKET_Identification', message.payload);
                    break;
                default:
                    console.log("No encontre accion de ws: "+decoded.action);

            }
        }
        
        

    },

    // mutations for reconnect methods
    SOCKET_RECONNECT(state, count) {
        console.log('SOCKET_RECONNECT');
        console.log(state, count);
    },

    SOCKET_RECONNECT_ERROR(state) {
        console.log('SOCKET_RECONNECT_ERROR');
        state.socket.reconnectError = true;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};