<template>
  <div class="d-flex justify-content-center align-items-center">
    <b-container class="pt-6" style="max-width: 40vh;">
      <b-card>
        <b-row>
          <b-col class="mb-2">
            <img src="@/assets/img/logo.png" width="100%" />
            <h3 align="center">Administración Central</h3>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Usuario:">
              <b-form-input v-model="form.username" type="text" placeholder="Usuario" required>
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Password:">
              <b-form-input v-on:keyup.enter="login" type="password" v-model="form.password" placeholder="Password"
                required></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="my-3">
            <b-button @click="login" block variant="success">Entrar</b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Signin",
  components: {},
  data() {
    return {
      color: '#673AB7',
      tenant: "",
      form: {
        username: "",
        password: "",
      },
    };
  },
  mounted() {
    this.tenant = this.$store.getters["server/tenant"]
    console.log(process.env.NODE_ENV)
  },
  async created() {
  },
  methods: {
    makeToast(title, content, variant) {
      this.$bvToast.toast(content, {
        title,
        variant,
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
    login() {
      let that = this
      this.$store.dispatch("server/changeTenantData", this.tenant);
      axios
        .post(this.$backDomain + "user/login", { username: this.form.username, password: this.form.password })
        .then(function (response) {
          console.log(response.data);
          let loginInfo=response.data
          const token = response.data.access_token
          const base64Url = token.split('.')[1];
          const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
          const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          }).join(''));
          let jP = JSON.parse(jsonPayload)
          localStorage.setItem('user-token', token) // store the token in localstorage
          axios.defaults.headers.common['Authorization'] = "Bearer " + token
          console.log("saving to user-token-time")
          localStorage.setItem('user-token-time', jP.exp) // store the token in localstorage
          axios.get('/user/' + loginInfo.user.id + "/actions")
            .then(resp => {
              that.$store.dispatch('auth/setActions',resp.data.map(a=>a.rrn))
              console.log(resp.data.map(a=>a.rrn))
            }).catch(err => {
              console.log(err)
            })
          that.$router.push("/selecttenant").catch();
        })
        .catch(function (err) {
          console.log(err.request.response)
          that.makeToast("Error", err.request.response, "danger")
        })
    }, //end getVentasDates
  },
};
</script>