import Vue from 'vue'

import App from './App.vue'
import './registerServiceWorker'
import router from './router/index.js'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import DashboardPlugin from './plugins/dashboard-plugin';
Vue.use(DashboardPlugin);

import JsonCSV from 'vue-json-csv'
Vue.component('downloadCsv', JsonCSV)


import datePicker from 'vue-bootstrap-datetimepicker';
Vue.use(datePicker); // Register datePicker
Vue.prototype.$backDomain=""
Vue.use(VueAxios, axios)
if (process.env.NODE_ENV === 'production') {
  Vue.prototype.$backDomain = 'https://pos.tizon.pereztecnologia.com/';
  axios.defaults.baseURL = 'https://pos.tizon.pereztecnologia.com/'+ localStorage.getItem('tenant')
}else{
  Vue.prototype.$backDomain=process.env.VUE_APP_SERVER_DOMAIN
  axios.defaults.baseURL = process.env.VUE_APP_SERVER_DOMAIN+localStorage.getItem('tenant')
}
const token = localStorage.getItem('user-token')
if (token) {
  axios.defaults.headers.common['Authorization'] = "Bearer "+token
}

import vSelect from 'vue-select'
Vue.component('vu-select', vSelect)
import "vue-select/dist/vue-select.css";

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
