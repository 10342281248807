<template>
    <div>
        <b-modal :hideHeaderClose="true" no-close-on-esc no-close-on-backdrop @ok="reenviarFactura" id="modal-reenviarFactura" size="xl" title="Reenviar Factura">
            <b-overlay :show="loading" rounded="sm">
                <b-row>
                    <b-col>
                        <b-alert :variant="alert.variant" :show="alert.show">{{ alert.msj }}</b-alert>
                    </b-col>
                </b-row>
                <div v-if="showContent">
                    <b-row>
                        <b-col>
                            <p>Introduce el mail al que quieres que se envíe la factura</p>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col lg="12" md="12">
                            <b-form-group label="Email">
                                <b-form-input v-model="email"></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
                <template #overlay>
                    <div class="text-center">
                        <b-icon icon="stopwatch" font-scale="3" animation="cylon"></b-icon>
                        <p>Estamos reenviando tu factura, esto toma algun tiempo</p>
                    </div>
                </template>
            </b-overlay>
            <template #modal-footer>
                <div class="w-100">
                    <b-button :disabled="loading" variant="danger" size="sm" class="float-right" @click="closeModal">
                        Cerrar
                    </b-button>
                    <b-button :disabled="loading" variant="success" size="sm" class="float-right"
                        @click="reenviarFactura">
                        Reenviar
                    </b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
import axios from "axios";

export default {
    name: "ReenviarFactura",
    props: ["factura_id"],
    data: () => {
        return {
            loading: false,
            showContent: true,
            alert: {
                show: false,
                variant: "danger",
                msj: ""
            },
            email: "",
            factura: {
                email: "",
                cancelado: 0,
                sucursal_id: 0
            }
        };
    },
    computed: {},
    mounted() {
        if (this.factura_id > 0) {
            this.getFactura()
        }
    },
    watch: {
        factura_id(n) {
            if (n > 0) {
                this.getFactura()
            }
        }
    },
    methods: {
        openModal() {
            this.showContent = true
            this.alert.show = false
            this.$bvModal.show("modal-reenviarFactura")
        },
        closeModal() {
            this.$bvModal.hide("modal-reenviarFactura")
        },
        reenviarFactura(e) {
            e.preventDefault()
            let that = this
            axios
                .post("/company/1/sucursal/" + this.factura.sucursal_id + "/factura/" + this.factura_id + "/reenviar/" + this.email)
                .then(function (response) {
                    that.makeToast(
                        "Exito",
                        response.data.message,
                        "success"
                    );
                    console.log(response.data)
                    that.$emit('sent', response.data)
                    that.closeModal()
                })
                .catch(function (err) {
                    console.log(err.request.response)
                    that.alert.show = true
                    that.alert.msj = err.request.response
                });
        },
        getFactura() {
            let that = this;
            that.showContent = true
            that.alert.show = false
            axios
                .get("/company/1/factura/" + this.factura_id)
                .then(function (response) {
                    console.log(response.data)
                    that.factura = response.data

                    if (that.factura.cancelado == 1) {
                        that.alert.show = true
                        that.alert.variant = "danger"
                        that.alert.msj = "Dicha factura se encuentra cancelada"
                        that.showContent = false
                    }
                })
                .catch(function (err) {
                    that.alert.show = true
                    that.alert.variant = "danger"
                    that.alert.msj = err.request.response
                });
        },
        makeToast(title, content, variant) {
            this.$bvToast.toast(content, {
                title,
                variant,
                appendToast: true,
            });
        },
    },
};
</script>