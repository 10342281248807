<template>
  <div>
    <Nav></Nav>

    <b-row>
      <b-col>
        <h1>Clientes Facturación</h1>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-button variant="success" @click="$refs.nuevoCliente.createClient()">Nuevo Cliente</b-button>
      </b-col>
    </b-row>
    <b-row class="mx-3">
      <b-col lg="3" md="12">
        <b-form-group label="Sucursal">
          <vu-select :reduce="(x) => x.id" v-model="sucursal_id" :options="sucursales"></vu-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group label="Buscar:">
          <b-form-input type="search" v-model="search" @keyup="page = 1" debounce="500"></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-table striped hover :items="clientes" :fields="tableFields" responsive :per-page="byPage"
          :current-page="page">
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>

          <template #cell(buttons)="data">
            <b-button-group>
              <b-button variant="info" @click="$refs.nuevoCliente.editClient(data.item.uid)">Editar</b-button>
            </b-button-group>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-pagination v-model="page" :total-rows="clientes.length" :per-page="byPage"
          aria-controls="my-table"></b-pagination>
      </b-col>
    </b-row>
    <CreateFacturacionCliente ref="nuevoCliente" :sucursal_id="sucursal_id" @updated="getClients"></CreateFacturacionCliente>
  </div>
</template>
<script>
import Nav from "../components/Nav.vue";
import CreateFacturacionCliente from "../components/CreateFacturacionCliente.vue"

import axios from "axios";
export default {
  name: "FacturaClientes",
  components: { Nav, CreateFacturacionCliente},
  data() {
    return {
      byPage: 20,
      page: 1,
      sucursal_id: null,
      search: "",
      clientes: [],
      sucursales:[],
      tableFields: [
        { key: "id", label: "ID", sortable: true, sortDirection: "asc" },
        { key: "rfc", label: "RFC", sortable: true },
        { key: "razon_social", label: "Razon Social", sortable: true },
        { key: "nombre", label: "Nombre", sortable: true },
        { key: "apellidos", label: "Apellidos", sortable: true },
       
        { key: "buttons" },
      ],
    };
  },
  mounted() {
    this.getSucursales();
  },
  watch:{
    sucursal_id(){
      if (this.sucursal_id > 0) {
        this.getClients()
      }
    },
    search(){
      this.getClients()
    }
  },
  methods: {
    cleanNewUser() {
      this.newuser.name = "";
      this.newuser.password = "";
      this.newuser.email = "test@mail.com";
      this.newuser.username = "";
      this.newuser.cellphone = "5511223344";
    },
    newUser(event) {
      event.preventDefault();
      let that = this;
      axios
        .post("/user", this.newuser)
        .then(function (data) {
          that.makeToast(
            "Exito",
            data.response.username + " creado",
            "success"
          );
          that.cleanNewUser();
          that.getAllUsers();
          that.search = data.response.username;
        })
        .catch(function (err) {
          console.log(err.request.response);
          that.makeToast("Error", err.request.response, "danger");
        });
    },

    searchGroup(id) {
      return (
        this.clientes.find((element) => element.id == id) || { name: "error" }
      );
    },
    getclientes() {
      let that = this;
      axios
        .get("/company/1/sucursal/1/facturacion/clientes")
        .then(function (response) {
          that.clientes = response.data;
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    getSucursales() {
      let that = this;
      axios
        .get("/sucursals")
        .then(function (response) {
          let sucursales = response.data.map((x) => {
            return { id: x.id, label: x.nombre }
          })
          that.sucursales = sucursales
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    getClients() {
      
      let that = this;
      let xtraSearch=""
      if(this.search.length>0){
        xtraSearch="?search="+this.search
      }
      axios
        .get("/company/1/sucursal/" + this.sucursal_id + "/facturacion/clientes"+xtraSearch)
        .then(function (response) {
          // let uso = response.data.map((x) => { return { id: x.uid, label: x.rfc + " - " + x.razon_social } })
          that.clientes = response.data
          console.log(response.data)
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    makeToast(title, content, variant) {
      this.$bvToast.toast(content, {
        title,
        variant,
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
  },
};
</script>
