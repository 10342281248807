<template>
  <div>
    <Nav></Nav>
    <b-container fluid="xl">
      <b-row>
        <b-col>
          <h1> Visor de Menus de Uber</h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button @click="getMenu" variant="default" size="sm"><b-icon icon="arrow-clockwise"></b-icon></b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="my-3">
          <vu-select v-model="selectedId" :options="sucursalesSelect" @input="getMenu"></vu-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <p>ID: {{ selectedId.id }}</p>
        </b-col>
      </b-row>
      <div v-for="menut in menu.menus" :key="menut.id">
        <b-row>
          <b-col>
            <h1>Menu {{ menut.title.translations.en }}</h1>
          </b-col>
        </b-row>

        <div v-for="category_id in menut.category_ids" :set="category = categoryById(category_id)" :key="category_id.id">
          <b-row>
            <b-col>
              <h2>{{ category.title.translations.en }}</h2>
            </b-col>
          </b-row>
          <b-row>
            <b-col v-for="entity in category.entities" :set="item = itemById(entity.id)" :key="entity.id" sm="12" md="4"
              xl="3">
              <b-card :title="item.title.translations.en" :img-src="item.image_url" img-top>
                <b-card-text>
                  ${{ item.price_info.price / 100 }}&nbsp;&nbsp;&nbsp;<b-badge v-if="isSuspended(item)"
                    variant="danger">Suspendido </b-badge><b-badge v-else variant="success">Activo</b-badge>

                </b-card-text>
                <b-button size="sm" v-if="isSuspended(item)"
                  @click="suspension_action.itemToSuspendId = entity.id; finishDeactivation(true)"
                  variant="primary">Reactivar</b-button><b-button size="sm" v-else variant="danger"
                  @click="startDeactivate(entity.id)">Suspender</b-button>
              </b-card>
            </b-col>
          </b-row>
        </div><!--Termina categorias-->
      </div><!--Termina Menu-->
    </b-container>
    <b-modal @ok="finishDeactivation(false)" id="modal-suspend" title="Suspender Producto">
      <p class="my-4">Ingresa hasta cuando deseas que tu producto sea suspendido:</p>
      <b-form-input v-model="suspension_action.time_until_suspension" type="datetime-local"></b-form-input>
    </b-modal>
  </div>
</template>
<script>
import Nav from "../components/Nav.vue";
import axios from "axios";
export default {
  name: "UberMenuViewer",
  components: { Nav },
  data() {
    return {
      sucursales: [],
      sucursalesSelect: [],
      selectedId: "",
      menu: {
        menus: [],
        categories: [],
        items: [],
        modifier_groups: []
      },
      suspension_action: {
        itemToSuspendId: "",
        time_until_suspension: "",
      }
    };
  },
  activated() {

  },
  created() {
    this.getSucursales()
  },
  computed: {

  },
  methods: {
    startDeactivate(id) {
      this.suspension_action.itemToSuspendId = id
      this.$bvModal.show("modal-suspend")
    },
    finishDeactivation(state) {
      let suspensionbdy
      if (state) {//activate
        var timetosuspend = new Date()
        suspensionbdy = { suspension_info: { suspension: { suspend_until: Math.floor(timetosuspend.getTime() / 1000), reason: "Out of stock" } } }
      } else {//suspend
        var timetosuspend2 = new Date(Date.parse(this.suspension_action.time_until_suspension))
        suspensionbdy = { suspension_info: { suspension: { suspend_until: Math.floor(timetosuspend2.getTime() / 1000), reason: "Out of stock" } } }
      }
      let that = this;
      console.log(suspensionbdy)
      console.log("store: " + this.selectedId.id + " item " + this.suspension_action.itemToSuspendId)
      axios
        .post("/uber/store/" + this.selectedId.id + "/menu/item/" + this.suspension_action.itemToSuspendId, suspensionbdy)
        .then(function () {
          that.getMenu()
          that.makeToast("Exito", "Exito al suspender tu producto", "success")
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    getSucursales() {
      let that = this;
      axios
        .get("/sucursals")
        .then(function (response) {
          that.sucursales = response.data;
          let map = []
          map = that.sucursales.map((x) => { return { label: x.nombre, id: x.uber_store_id, disabled: x.uber_store_id == null } })
          that.sucursalesSelect = map
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    itemById(id) {
      return this.menu.items.find((x) => { return x.id == id })
    },
    categoryById(id) {
      return this.menu.categories.find((x) => { return x.id == id })
    },
    clearMenu(){
      this.menu={
        menus: [],
        categories: [],
        items: [],
        modifier_groups: []
      }
    },
    getMenu() {
      let that = this;
      this.clearMenu()
      if(this.selectedId.id==null){
        this.$bvModal.msgBoxOk("Esta sucursal no cuenta con una tienda en rappi, configurala en tu administrador",{
          title: 'Error',
          okVariant: 'success',
          centered: true
        })
        this.selectedId=null
        return
      }
      axios
        .get("/uber/store/" + this.selectedId.id + "/menu")
        .then(function (response) {
          console.log(response.data)
          that.menu = response.data
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    isSuspended(item) {
      if (item.suspension_info !== undefined) {
        let suspension_info = item.suspension_info
        if (suspension_info.suspension !== undefined) {
          if (suspension_info.suspension.suspend_until !== undefined) {
            return (new Date(new Date() - new Date(suspension_info.suspension.suspend_until * 1000)) < 0)
          }
        }
      }
      return false
    },

    makeToast(title, content, variant) {
      this.$bvToast.toast(content, {
        title,
        variant,
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
  },
};
</script>
