<template>
    <div>
        <b-modal :hideHeaderClose="true"  no-close-on-esc no-close-on-backdrop @ok="closeModal"  @cancel="closeModal" id="modal-CuentaDetalle" size="xl" title="Información Cuenta">
            <b-row>
                <b-col xl="12" lg="12" md="12" sm="12">
                    <b-overlay :show="loadingCuenta" rounded="sm">
                        <b-card border-variant="primary" class="my-1" align="left">
                            <b-row cols="1" cols-sm="2" cols-md="4" cols-lg="4">
                                <b-col class="my-1">
                                    <b-button block size="sm" variant="primary" class="h-100"
                                        @click="closeModal()"><b-icon-x></b-icon-x> CERRAR
                                        {{ helpKeys ? "(ESC)" : "" }}</b-button>
                                </b-col>
                                <b-col class="my-1">
                                    <b-button block size="sm" variant="primary" class="h-100"
                                        @click="queryActualTicket">ACTUALIZAR
                                    </b-button>
                                </b-col>
                                <b-col class="my-1">
                                    <b-button block size="sm" variant="primary" class="h-100"
                                        @click="reUploadTicket">REENVIAR
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-card>
                        <b-alert class="my-1" variant="success" show v-if="actualCuenta.discount_type_id != 1">Descuento
                            Aplicado:{{ actualCuenta.discount_type.name }} ({{
            actualCuenta.discount_percentage
        }}%)
                        </b-alert>
                        <b-alert class="my-1" variant="danger" show v-if="actualCuenta.canceled">Cuenta Cancelada
                        </b-alert>
                        <b-card no-body border-variant="primary" class="my-1 p-3">
                            <b-row cols-xl="3" cols-lg="3" cols-md="2" cols="1">
                                <b-col>
                                    <p class="my-0">
                                        Sucursal: {{ actualCuenta.sucursal_id }}<br>
                                        ID Sistema: {{ actualCuenta.id }}<br>
                                        Mesa: {{ actualCuenta.table_no }}<br>
                                        Tipo: {{ actualCuenta.restaurant_area.name }}<br>
                                        Mesero: {{ actualCuenta.waiter }}<br>
                                        Personas: {{ actualCuenta.persons }}
                                    </p>
                                </b-col>
                                <b-col>
                                    <p class="my-0">
                                        Folio: {{ actualCuenta.numticket }}<br>
                                        Apertura: {{ actualCuenta.date_open }}<br>
                                        Cierre: {{ actualCuenta.date_close }}<br>
                                        Impresa: <b-icon-check-circle-fill v-if="actualCuenta.printed"
                                            variant="success"></b-icon-check-circle-fill><b-icon-x-circle-fill v-else
                                            variant="danger"></b-icon-x-circle-fill>
                                        Pagada: <b-icon-check-circle-fill v-if="actualCuenta.paid"
                                            variant="success"></b-icon-check-circle-fill><b-icon-x-circle-fill v-else
                                            variant="danger"></b-icon-x-circle-fill>
                                        Cancelada: <b-icon-check-circle-fill v-if="actualCuenta.canceled"
                                            variant="success"></b-icon-check-circle-fill><b-icon-x-circle-fill v-else
                                            variant="danger"></b-icon-x-circle-fill>
                                        Propina: {{ actualCuenta.tip }}
                                    </p>
                                </b-col>
                                <b-col>
                                    <p class="my-0">
                                        Turno: {{ actualCuenta.shift_id }}<br>
                                        Subtotal: {{ actualCuenta.total }}<br>
                                        Descuentos: {{ actualCuenta.discount_amount }}<br>
                                        Total: {{ actualCuenta.total_with_discount }}<br>
                                    </p>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <p class="my-0">Comentario: {{ actualCuenta.comment }}</p>
                                </b-col>
                            </b-row>
                        </b-card>
                        <b-card v-if="actualCuenta.delivery_company != null" title="Delivery" border-variant="primary"
                            class="my-1 h-100">
                            <b-row>
                                <b-col>
                                    <p class="my-0">
                                        Canal: {{ actualCuenta.delivery_company }}<br>
                                        ID: {{ actualCuenta.delivery_id }}<br>
                                        Status: {{ actualCuenta.delivery_status }}
                                    </p>
                                </b-col>
                                <b-col>
                                    <p class="my-0">
                                        Pago: {{ actualCuenta.delivery_payment }}<br>
                                        Tipo: {{ actualCuenta.delivery_type }}
                                    </p>
                                </b-col>
                                <b-col>
                                    <p class="my-0">
                                    </p>
                                </b-col>
                            </b-row>
                        </b-card>
                        <b-card title="Productos" border-variant="primary" class="my-1 h-100">
                            <b-row>
                                <b-col>
                                    <b-table small  striped hover responsive sticky-header="90vh"
                                        :items="actualCuenta.ticket_products" :fields="actualCuentaFields">
                                        <template #cell(cancelled)="data">
                                            <div v-if="data.item.cancelled">
                                                <b-icon icon="dash-circle-fill" variant="danger"></b-icon> Cancelado
                                            </div>

                                        </template>
                                    </b-table>
                                </b-col>
                            </b-row>
                        </b-card>
                        <b-card title="Pagos" border-variant="primary" class="my-3 h-100">
                            <b-row>
                                <b-col>
                                    <b-table small striped hover filter="true" responsive sticky-header="90vh"
                                        :items="actualCuenta.payments" :fields="paymentFields">

                                        <template #cell(buttons)="data">
                                            <b-dropdown text="Mas" variant="primary" size="sm">
                                                <b-dropdown-item :disabled="!data.item.status"
                                                    @click="sendCancelPayment(data.item.id)">Cancelar</b-dropdown-item>
                                            </b-dropdown>
                                        </template>
                                        <template #cell(status)="data">
                                            <div v-if="!data.item.status">
                                                <b-icon icon="x-circle-fill" variant="danger"></b-icon> Cancelado
                                            </div>
                                            <div v-if="data.item.status">
                                                <b-icon icon="check-circle-fill" variant="success"></b-icon> Activo
                                            </div>
                                        </template>
                                    </b-table>
                                </b-col>
                            </b-row>
                        </b-card>
                        <b-card title="Log" border-variant="primary" class="my-1 h-100">
                            <b-row>
                                <b-col>
                                    <b-table class="h-100" small striped hover :items="log"
                                        :fields="logFields">
                                    </b-table>
                                </b-col>
                            </b-row>
                        </b-card>
                    </b-overlay>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>
<script>
import axios from "axios";
export default {
    name: "CuentaDetalle",
    props: ["ticket_id"],
    data: () => {
        return {
            log: [],
            logFields: [
                { key: "action_id", label: "ID" },
                { key: "action_date", label: "Fecha" },
                { key: "description", label: "Descripcion" },
            ],
            loadingCuenta: false,
            paymentFields: [
                { key: "id", label: "ID" },
                { key: "payment_method.name", label: "Metodo de pago" },
                { key: "payment_amount", label: "Monto" },
                { key: "tip_amount", label: "Propina" },
                { key: "status", label: "Status" },
                { key: "buttons", label: "" }
            ],
            actualCuentaFields: [
                { key: "movement", label: "Mov" },
                { key: "quantity", label: "Cant" },
                { key: "product_code", label: "Clave" },
                { key: "cancelled", label: "Canc." },
                { key: "product.ndescription", label: "Descripcion" },
                { key: "discount_percentage", label: "Descuento" },
                { key: "price", label: "Precio" },
                { key: "total_with_discount", label: "Total" },
            ],
            actualCuenta: {
                ticket_products: [],
                restaurant_area: {
                    name: "",
                },
                discount_type: {
                    name: "",
                },
            },
            activeTicketId: 0,
        };
    },
    computed: {
        activeTicket() {
            return this.$store.getters["api/activeTicket"];
        },
        imgLink() {
            return this.$store.getters["api/imgLink"];
        },
        products() {
            return this.$store.getters["api/allProducts"];
        },
        activeTickets() {
            return this.$store.getters["api/allActiveTickets"];
        },
        restAreas() {
            return this.$store.getters["api/allRestAreas"];
        },
        printed() {
            return this.actualCuenta.printed == 1;
        },
    },
    mounted() {
       
    },
    watch: {
        ticket_id(n) {
            if (n > 0) {
                this.activeTicketId=n
                this.openModal()
            }
        }
    },
    methods: {
        openModal() {
            this.$bvModal.show("modal-CuentaDetalle")
            this.changeActualTicket(this.activeTicketId)
        },
        closeModal() {
            this.$bvModal.hide("modal-CuentaDetalle")
            this.activeTicketId = 0;
            this.$emit('changeTicketId',0)
        },
        changeActualTicket(ntick) {
            console.log(ntick);
            this.activeTicketId = ntick;
            this.queryActualTicket();
        },
        reUploadTicket() {
            let that = this
            axios
                .post("/sucursal/" + that.actualCuenta.sucursal_id + "/action/reUploadTicket", this.actualCuenta.id)
                .then(function (response) {
                    console.log(response.data)
                    that.loadingCuenta = false;
                    that.makeToast("success", "Exito", "La cuenta se actualizara en algunos minutos");
                })
                .catch(function (r) {
                    that.loadingCuenta = false;
                    that.makeToast("danger", "Error", r.response.data);
                });
        },
        sendCancelPayment(id) {
            let that = this
            axios
                .post("/sucursal/" + that.actualCuenta.sucursal_id + "/action/cancelTicketPayment", id)
                .then(function (response) {
                    console.log(response.data)
                    that.loadingCuenta = false;
                    that.makeToast("success", "Exito", "La cuenta se actualizara en algunos minutos");
                })
                .catch(function (r) {
                    that.loadingCuenta = false;
                    that.makeToast("danger", "Error", r.response.data);
                });
        },
        queryActualTicket() {
            this.loadingCuenta = true;
            let that = this;
            axios
                .get("/ticket/sid/" + this.activeTicketId)
                .then(function (response) {
                    that.actualCuenta = response.data;
                    that.loadingCuenta = false;
                })
                .catch(function (r) {
                    that.loadingCuenta = false;
                    that.makeToast("danger", "Error", r.response.data);
                });
        },
        getActiveTicket: function (row) {
            this.activeTicketId = row.sid;
            this.queryActualTicket();
        },
        makeToast(variant = null, title = "", text = "") {
            this.$bvToast.toast(text, {
                title: title,
                variant: variant,
                solid: true,
                appendToast: true,
            });
        }
    }
}
</script>