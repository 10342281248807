<template>
  <div>
    <Nav></Nav>
    <b-container fluid>
      <b-row>
        <b-col>
          <h1>Menus Uber</h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <p>Seleccione uno de los menus para editarlo o, crea uno nuevo <b-button variant="success"
              v-b-modal.modal-createMenu>Crear
              Menu</b-button>
          </p>
          <b-form-group label="Menus:">
            <vu-select :reduce="(x) => x.id" v-model="actualMenuId" :options="uberMenusOptions"></vu-select>
          </b-form-group>
        </b-col>
      </b-row>



    </b-container>
    <b-container fluid v-if="$route.params.id != undefined">
      <b-row>
        <b-col>
          <b-nav tabs>
            <!-- <b-nav-item>'s with child routes. Note the trailing slash on the first <b-nav-item> -->
            <b-nav-item :to="'/uber/' + $route.params.id" exact exact-active-class="active">General</b-nav-item>
            <b-nav-item :to="'/uber/' + $route.params.id + '/menus'" exact exact-active-class="active">Menus</b-nav-item>
            <b-nav-item :to="'/uber/' + $route.params.id + '/categories'" exact
              exact-active-class="active">Categorias</b-nav-item>
            <b-nav-item :to="'/uber/' + $route.params.id + '/items'" exact
              exact-active-class="active">Artículos</b-nav-item>
            <b-nav-item :to="'/uber/' + $route.params.id + '/personalizations'" exact exact-active-class="active">Grupos
              de Modificadores</b-nav-item>
          </b-nav>
        </b-col>
      </b-row>
      <b-card no-body>
        <div class="m-3">
          <b-row>
            <b-col>
              <h4>Esta editando el menu: {{ menuname }}</h4>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <router-view></router-view>
            </b-col>
          </b-row>
        </div>
      </b-card>

    </b-container>
    <b-modal id="modal-createMenu" title="Crear Menu" @ok="createMenuF">
      <b-row>
        <b-col>
          <p class="my-4">Crea aqui un nuevo menu de uber, en la pestaña sucursales, podras asignar el menu a cada
            sucursal.</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Nombre:">
            <b-form-input v-model="createMenu.name" type="text" required></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
import Nav from "../components/Nav.vue";
import axios from "axios";
export default {
  name: "Uber",
  components: { Nav },
  data() {
    return {
      uberMenus: [],
      uberMenusOptions: [],
      actualMenuId: undefined,
      createMenu: {
        name: ""
      }
    };
  },
  watch: {
    actualMenuId(to, f) {
      if (f != to && this.$route.params.id != to) {
        this.$router.push({ params: { id: to } })
      }
    }
  },
  computed: {
    menuname() {
      if (this.actualMenuId != undefined && this.uberMenus.length > 0) {
        console.log(this.actualMenuId)
        let x = this.uberMenus.find((x) => { return x.uuid == this.actualMenuId })
        return x.name
      } else {
        return ""
      }
    }
  },
  mounted() {
    this.getAllUberMenus();
    this.actualMenuId = this.$route.params.id
  },
  methods: {
    getAllUberMenus() {
      let that = this;
      axios
        .get("/uber/mastermenus")
        .then(function (response) {
          that.uberMenus = response.data;
          let persfil = response.data
            .map((pers) => {
              return { id: pers.uuid, label: pers.name };
            });
          let persfils = [{ id: undefined, label: "" }]
          persfils.push(...persfil)
          that.uberMenusOptions = persfils;
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    createMenuF() {
      let that = this;
      axios
        .post("/uber/mastermenu", this.createMenu)
        .then(function (response) {
          that.getAllUberMenus()
          that.actualMenuId = response.data.uuid
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    makeToast(title, content, variant) {
      this.$bvToast.toast(content, {
        title,
        variant,
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
  },
};
</script>
