<template>
    <div>
        <Nav> </Nav>
        <b-container>
            <b-row>
                <b-col>
                    <h1 v-if="actualPaymentmethod.id > 0">
                    Forma de Pago {{ this.actualPaymentmethod.id + " "+ this.actualPaymentmethod.name}}</h1>
                    <h1 v-else> Crear Forma de Pago  </h1>
                </b-col>
            </b-row>
            
      

            <b-card>
                <b-form>
                    <b-row>
                      <b-col md="6" sm="12">
                            <b-form-group label="Nombre:">
                                <b-form-input v-model="actualPaymentmethod.name"
                                type="text"
                                required>
                                </b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col md="6" sm="12">
                            <b-form-group label="Nombre Corto:">
                                <b-form-input v-model="actualPaymentmethod.short_name"
                                type="text"
                                required>
                                </b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col md="6" sm="12">
                            <b-form-group label="Comisión:">
                                <b-form-input v-model="actualPaymentmethod.comision"
                                type="number"
                                required>
                                </b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col md="6" sm="12">
                            <b-form-group label="Forma de factura:">
                                <b-form-input v-model="actualPaymentmethod.factura_forma"
                                type="text"
                                required>
                                </b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col xl="6" md="12">
                            <b-form-group label="Visible: ">
                                <b-form-checkbox v-model="actualPaymentmethod.visible">
                                    Activo
                            </b-form-checkbox>
                            </b-form-group>
                        </b-col>
                        <b-col xl="6" md="12">
                            <b-form-group label="Acepta Propina: ">
                                <b-form-checkbox v-model="actualPaymentmethod.accept_tip">
                                    Activo
                            </b-form-checkbox>
                            </b-form-group>
                        </b-col>

                    </b-row>
                    <b-row class="mt-3">
                        <b-button v-if="actualPaymentmethod.id > 0"
                            block
                            variant="success"
                            @click="updatePaymentmethod">
                            Guardar
                        </b-button>
                        <b-button v-else block variant="success" @click="createPaymentmethod"> Crear </b-button>
                    </b-row>
                </b-form>
            </b-card>
        </b-container>
    </div>
</template>


<script>
import Nav from "../components/Nav.vue";
import axios from "axios";
export default {
  name: "Paymentmethod",
  components: { Nav },
  data() {
    return {
      actualPaymentmethod: {
        id: 0,
        name:"",
        short_name:"",
        image:"",
        comision: "0",
        visible: true,
        accept_tip: true,
        factura_forma:"",
      },
      
    };
  },
  mounted() {
    if (this.$route.params.id > 0) {
      console.log("parameters > 0")
      this.actualPaymentmethod.id = this.$route.params.id;
      console.log(this.actualPaymentmethod.id);
      this.getPaymentmethod();
    }
  },
  methods: {
    getPaymentmethod() {
      console.log(this.actualPaymentmethod);
      let that = this;
      axios
        .get("/paymentmethod/" + this.actualPaymentmethod.id)
        .then(function (response) {
          that.actualPaymentmethod = response.data;
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    updatePaymentmethod() {
      let that = this;
      axios
        .put("/paymentmethod/" + this.actualPaymentmethod.id, this.actualPaymentmethod)
        .then(function (response) {
          that.actualPaymentmethod = response.data;
          that.makeToast("Exito", "exito al guardar", "success");
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    createPaymentmethod() {
      let that = this;
      axios
        .post("/paymentmethod", this.actualPaymentmethod)
        .then(function (response) {
          that.actualPaymentmethod = response.data;
          console.log(response.data)
          that.makeToast("Exito", "exito al crear", "success");
          that.$router.push("/paymentmethod/" + response.data.id);
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    makeToast(title, content, variant) {
      this.$bvToast.toast(content, {
        title,
        variant,
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
  }
  }
</script>