<template>
  <div>
    <b-row v-for="(category, key, index) in categorizeditems" :key="index">
      <b-col>
        <b-card>
          <b-row>
            <b-col>
              <h2>{{ key }}</h2>
            </b-col>
          </b-row>
          <b-row cols="1" cols-sm="3" cols-lg="4" cols-xl="6" >
            <b-col class="p-3" v-for="item in category" :key="item.id">
              <card  class="h-100 d-flex flex-row flex-md-column align-items-center justify-content-center" no-body>
                <b-row align-v="center" class="m-1">
                  <b-col>
                    <b-img fluid-grow rounded :src="'https://pos.tizon.pereztecnologia.com/'+item.imageUrl" ></b-img>
                  </b-col>
                </b-row>
                <b-row align-v="center" style="min-width: 50%;" class="m-1">
                  <b-col>
                    <h4 class="mb-0">
                      {{item.name}}
                    </h4>
                    <span :class="item.status?'text-success':'text-danger'">● </span><small>{{ item.status?'Habilitado':'Deshabilitado' }}</small>
                    <p class="text-sm text-muted mb-0">{{  item.description.slice(0,70) }}...</p>
                    <h4 class="mb-0"> ${{ item.price }}</h4>
                    
                  </b-col>
                </b-row>
              </card>

            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "ComponentRappiOverview",
  data() {
    return {
      items: [],
      categories: [],
      categorizeditems:{},
      mainProps: { blank: true, blankColor: '#777' }
    };
  },
  mounted() {
    this.getAllUsers()
  },
  methods: {
    getAllUsers() {
      if (this.$route.params.id == 0 || this.$route.params.id == undefined) {
        return
      }
      let that = this;
      axios
        .get("/rappi/menu/" + this.$route.params.id + "/items")
        .then(function (response) {
          that.items = response.data
          that.categorizeditems=response.data.reduce(function(r,a){
            r[a.category.name] = r[a.category.name] || [];
            r[a.category.name].push(a);
            return r;
          }, Object.create(null))

        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
      axios
        .get("/rappi/menu/" + this.$route.params.id + "/categories")
        .then(function (response) {
          that.categories = response.data.sort((a, b) => { return a.sortingPosition - b.sortingPosition })
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    makeToast(title, content, variant) {
      this.$bvToast.toast(content, {
        title,
        variant,
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
  },
};
</script>
  