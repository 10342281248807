<template>
  <div>
    <Nav></Nav>
    <b-container>
      <b-row>
        <b-col>
          <h1 v-if="actualProduct.id > 0">
            Categoria Rappi
            {{ this.actualProduct.id + " " + this.actualProduct.name }}
          </h1>
          <h1 v-else>Crear Categoria Rappi</h1>
        </b-col>
      </b-row>
     
      <b-card>
        <b-form>
          <b-row>
            <b-col md="12" sm="12">
              <b-form-group label="Nombre:">
                <b-form-input
                  v-model="actualProduct.name"
                  type="text"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
            
          </b-row>

          <b-row class="mt-3">
            <b-button
              v-if="actualProduct.id > 0"
              block
              variant="success"
              @click="updateProduct"
              >Guardar</b-button
            >
            <b-button v-else block variant="success" @click="updateProduct"
              >Crear</b-button
            >
          </b-row>
        </b-form>
      </b-card>
      
    </b-container>
  </div>
</template>
<script>
import Nav from "../components/Nav.vue";
import axios from "axios";
export default {
  name: "RappiCategory",
  components: { Nav },
  data() {
    return {
      actualProduct: {
        id: 0,
        name: "",
        minQty: 0,
        maxQty: 0,
        sortingPosition: 0
      },
    };
  },
  beforeRouteUpdate(to, from) {
    console.log(to);
    console.log(from);
    this.initData();
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      console.log("creando");
      if (this.$route.params.id > 0) {
        console.log("producto no nuevo");
        this.actualProduct.id = this.$route.params.id;
        // this.actualPersonalization.product_id = parseInt(this.actualProduct.id);
        this.getProduct();
        // this.getProductRecipe();
      }
      console.log("termina creacion");
    },
    getProduct() {
      let that = this;
      axios
        .get("/rappi/menu/category/" + this.actualProduct.id)
        .then(function (response) {
          that.actualProduct = response.data;
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    
    updateProduct() {
      let that = this;
      if (this.actualProduct.id > 0) {
        axios
          .put("/rappi/menu/category" , this.actualProduct)
          .then(function (response) {
            that.actualProduct = response.data;
            that.makeToast("Exito", "exito al guardar", "success");
            that.getProduct();
          })
          .catch(function (err) {
            that.makeToast("Error", err.request.response, "danger");
          });
      } else {
        axios
          .post("/rappi/menu/category", this.actualProduct)
          .then(function (response) {
            that.makeToast("Exito", "exito al crear, redirigiendo", "success");
            console.log(response.data);
            that.$router.push({
              name: "RappiCategoryById",
              params: { id: response.data.id },
            });
          })
          .catch(function (err) {
            that.makeToast("Error", err.request.response, "danger");
          });
      }
    },

    makeToast(title, content, variant) {
      this.$bvToast.toast(content, {
        title,
        variant,
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
  },
};
</script>
