<template>
  <div>
    <Nav></Nav>
    <b-container fluid>
      <b-row>
        <b-col>
          <h1>Sucursales</h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col xs="12" sm="6" md="6" lg="4" xl="3">
          <b-card no-body class="bg-success">
            <!-- Card body -->
            <b-card-body>
              <b-row>
                <b-col>
                  <b-card-title title-tag="h5" class="text-uppercase text-muted mb-0 text-white">En Linea</b-card-title>
                  <span class="h2 font-weight-bold mb-0 text-white">{{ sucursalesEnLinea }}</span>
                </b-col>
                <b-col md="auto">
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col xs="12" sm="6" md="6" lg="4" xl="3">
          <b-card no-body class="bg-danger">
            <!-- Card body -->
            <b-card-body>
              <b-row>
                <b-col>
                  <b-card-title title-tag="h5" class="text-uppercase text-muted mb-0 text-white">Desconectadas</b-card-title>
                  <span class="h2 font-weight-bold mb-0 text-white">{{ sucursalesDesconectadas }}</span>
                </b-col>
                <b-col md="auto">
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>  
      <!-- <b-row>
        <b-col>
          <b-form-group label="Buscar:">
            <b-form-input type="search" v-model="search" @keyup="page=1" debounce="500"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row> -->
      <b-row>
        <b-col xs="12" sm="6" md="6" lg="4" xl="3" v-for="sucursal in sucursalsTable " v-bind:key="sucursal.id">
          <b-card class="">
            <b-row>
              <b-col >
                {{sucursal.nombre}}<br>{{ sucursal.version }}<br><b-badge pill v-if="sucursal.status" variant="success">ACTIVO</b-badge>
                <b-badge pill v-else variant="danger">INACTIVO</b-badge>
              </b-col>
              <b-col cols="auto">
                <b-button size="sm" variant="success" @click="$router.push('/sucursal/' + sucursal.id)">Ver Más +</b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <!-- <b-row>
        <b-col>
          <b-table
            stacked="sm"
            :filter="search"
            striped
            hover
            :fields="tableFields"
            :items="sucursalsTable"
            responsive="md"
            :per-page="byPage"
            :current-page="page"
          >
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>

            <template #cell(buttons)="data">
              <b-button-group vertical>
                <b-button size="sm" variant="success" @click="$router.push('/sucursal/' + data.item.id)"
                  >Ver Más +</b-button
                >
                
              </b-button-group>
            </template>
            <template #cell(group)="data">
              {{searchGroup(data.item.group).name}}
            </template>
            <template #cell(subgroup)="data">
              {{searchSubgroup(data.item.subgroup).name}}
            </template>
            <template #cell(status)="data"> 
                <b-badge pill v-if="data.item.status" variant="success">ACTIVO</b-badge>
                <b-badge pill v-else variant="danger">INACTIVO</b-badge>
            </template>
            <template #cell(image)="data"> 
              <img style="max-width: 8vh;" :src="axios.defaults.baseURL + '/' +data.item.image">
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-pagination
            v-model="page"
            :total-rows="sucursalsTable.length"
            :per-page="byPage"
            aria-controls="my-table"
    ></b-pagination>
        </b-col>
      </b-row> -->
    </b-container>
  </div>
</template>
<script>
import Nav from "../components/Nav.vue";
import axios from "axios";
export default {
  name: "Sucursales",
  components: { Nav },
  data() {
    return {
      sucursalesDesconectadas:null,
      sucursalesEnLinea:null,
      byPage:20,
      page:1,
      sucursalsTable: [],
      search: "",
      tableFields: [
        { key: "id", label: "ID", sortable: true, sortDirection: "desc", class:"align-middle"},
        { key: "nombre", label: "Nombre", sortable: true , class:"align-middle"},
        { key: "version", label: "Version", sortable: true  },
        { key: "status", label: "Status" , sortable: true },
        { key: "buttons", label: "" },
      ],
    };
  },
  activated(){
    this.getAllSucursals();
    
  },
  created() {
    this.getAllSucursals();
    
  },
  methods: {
    cleanNewSucursal() {
      this.newuser.name = "";
      this.newuser.password = "";
      this.newuser.email = "test@mail.com";
      this.newuser.username = "";
      this.newuser.cellphone = "5511223344";
    },
    newSucursal(event) {
      event.preventDefault();
      let that = this;
      axios
        .post("/user", this.newuser)
        .then(function (data) {
          that.makeToast(
            "Exito",
            data.response.username + " creado",
            "success"
          );
          that.cleanNewSucursal();
          that.getAllSucursals();
          that.search = data.response.username;
        })
        .catch(function (err) {
          console.log(err.request.response);
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    getAllSucursals() {
      let that = this;
      axios
        .get("/sucursals")
        .then(function (response) {
          that.sucursalsTable = response.data.sort((a, b) => { return a.nombre.localeCompare(b.nombre) })
          that.sucursalesDesconectadas=response.data.filter(x=>!x.status).length
          that.sucursalesEnLinea=response.data.filter(x=>x.status).length
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
   
    makeToast(title, content, variant) {
      this.$bvToast.toast(content, {
        title,
        variant,
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
  },
};
</script>
