<template>
  <div>
    <Nav></Nav>
    <b-container>
      <b-row>
        <b-col>
          <h1 v-if="actualProduct.id > 0">
            Producto Rappi
            {{ this.actualProduct.id + " " + this.actualProduct.name }}
          </h1>
          <h1 v-else>Crear Producto Rappi</h1>
        </b-col>
      </b-row>
      <b-row>
        <b-jumbotron class="p-3 mx-auto" v-if="actualProduct.id > 0">
          <b-col>
            <img style="height: 200px;" :src="axios.defaults.baseURL + '/' + this.actualProduct.imageUrl" />
            <b-button class="mx-5" @click="show = !show">Editar Imagen</b-button>
          </b-col>
        </b-jumbotron>
        <my-upload field="image" @crop-success="cropSuccess" @crop-upload-success="cropUploadSuccess"
          @crop-upload-fail="cropUploadFail" v-model="show" lang="es-MX" lang-type="es-MX" :no-circle="true"
          :width="992" :height="676" :url="
            axios.defaults.baseURL +
            '/rappi/item/' +
            this.actualProduct.id +
            '/image'
          " :params="imgparams" :headers="imgheaders" img-format="png"></my-upload>
      </b-row>
      <b-card>
        <b-form>
          <b-row>
            <b-col md="6" sm="12">
              <b-form-group label="SKU:">
                <b-form-input v-model="actualProduct.sku" disabled type="text" required></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6" sm="12">
              <b-form-group label="Nombre:">
                <b-form-input v-model="actualProduct.name" type="text" required></b-form-input>
              </b-form-group>
            </b-col>
            <b-col xl="6" md="12">
              <b-form-group label="Descripcion:">
                <b-form-input v-model="actualProduct.description" type="text" required></b-form-input>
              </b-form-group>
            </b-col>
            <b-col xl="6" md="12">
              <b-form-group label="Precio">
                <b-form-input v-model.number="actualProduct.price" type="number" required></b-form-input>
              </b-form-group>
            </b-col>
            <b-col xl="4" md="4" sm="12">
              <b-form-group label="Status:">
                <b-form-select v-model="actualProduct.status" :options="statusoptions"></b-form-select>
              </b-form-group>
            </b-col>
            <b-col xl="4" md="4" sm="12">
              <b-form-group label="Producto">
                <vu-select :reduce="(x) => x.id" v-model="actualProduct.product_id" :options="productOptions"></vu-select>
              </b-form-group>
            </b-col>
            <b-col xl="4" md="4" sm="12">
              <b-form-group label="Categoria">
                <!-- https://vue-select.org/guide/validation.html#required -->
                <vu-select :reduce="(x) => x.id" v-model="actualProduct.category_id" :options="rappiCategoriesOptions">
                </vu-select>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="mt-3">
            <b-button v-if="actualProduct.id > 0" block variant="success" @click="updateProduct">Guardar</b-button>
            <b-button v-else block variant="success" @click="updateProduct">Crear</b-button>
          </b-row>
        </b-form>
      </b-card>
      <b-row class="mt-3" v-if="actualProduct.id > 0">
        <b-col>
          <b-card>

            <b-row>
              <b-col md="6" lg="6" xl="6">
                <h3>Grupos de toppings</h3>
                <p>Los Grupos de Toppings son las agrupaciones de las opciones (ingredientes, adiciones, tamaños, etc.)
                  que le das al usuario para que personalice su producto.</p>
                <b-list-group>
                  <b-list-group-item v-for="p in productChildrencategories" :key="p.id"
                    class="flex-column align-items-start">
                    <div class="d-flex w-100 justify-content-between">
                      <b-form-radio v-model="actualChildrencategory" name="category-radios" :value="p">
                        {{p.id+" - "+p.name}}
                      </b-form-radio>
                      <b-button-group>

                        <b-button variant="primary" size="sm" @click="editChildrencategory(p)">
                          <b-icon-pencil-fill></b-icon-pencil-fill>
                        </b-button>
                        <b-button variant="danger" size="sm" @click="deleteChildrencategory(p.id)">
                          <b-icon-trash-fill></b-icon-trash-fill>
                        </b-button>
                      </b-button-group>
                    </div>
                  </b-list-group-item>
                  <b-list-group-item>
                    <b-button @click="childrencategoryCreateCollapseState=true;cleanChildrencategory()"
                      variant="primary">Agregar +</b-button>
                    <b-collapse id="collapse-create-childrencategory" v-model="childrencategoryCreateCollapseState"
                      class="mt-2">
                      <b-card>
                        <b-row>
                          <b-col>
                            <h4 class="card-text">Agregar categoria toppings</h4>
                            <p>Busca aqui gurpos de toppings de otros productos o crea uno nuevo mas abajo</p>
                            <vu-select :reduce="(x) => x.id" v-model="selectedChildrencategory"
                              :options="childrencategoriesOptions"></vu-select>
                          </b-col>
                        </b-row>
                        <b-jumbotron class="my-3 py-3" v-if="!selectedChildrencategory">
                          <b-row>
                            <b-col lg="12">
                              <b-form-group label="Nombre">
                                <b-form-input v-model="actualChildrencategory.name"
                                  placeholder="Si no existe inserta el nombre"></b-form-input>
                              </b-form-group>
                            </b-col>
                            <b-col lg="12">
                              <b-alert variant="success" show>Si tu grupo de toppings es obligatorio, coloca en Min: 1,
                                en caso contrario coloca 0</b-alert>
                            </b-col>
                            <b-col lg="6">
                              <b-form-group label="Max">
                                <b-form-input v-model.number="actualChildrencategory.maxQty" placeholder="Max">
                                </b-form-input>
                              </b-form-group>
                            </b-col>
                            <b-col lg="6">
                              <b-form-group label="Min">
                                <b-form-input v-model.number="actualChildrencategory.minQty" placeholder="Min">
                                </b-form-input>
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-jumbotron>
                        <b-row class="mt-3">
                          <b-col>
                            <b-button variant="success"
                              @click="createChildrencategory(()=>childrencategoryCreateCollapseState=false)">Aceptar
                            </b-button>
                            <b-button variant="danger"
                              @click="childrencategoryCreateCollapseState=false;cleanChildrencategory()">Cancelar
                            </b-button>
                          </b-col>
                        </b-row>



                      </b-card>
                    </b-collapse>

                  </b-list-group-item>
                </b-list-group>
              </b-col>
              <b-col md="6" lg="6" xl="6">
                <h3>Toppings</h3>
                <p>Los Topping son las opciones, dentro de un "grupo de toppings", que le das a tu usuario para
                  personalizar o modificar el producto.</p>
                <b-list-group>
                  <b-list-group-item v-for="p in childrencategoriesadd.filteredToppings" :key="p.id"
                    class="flex-column align-items-start">
                    <div class="d-flex w-100 justify-content-between">
                      <p>{{p.id+" - "+p.name}} </p>
                      <b-button-group>
                        <b-button variant="primary" size="sm" @click="cleanSubitem();editTopping(p)">
                          <b-icon-pencil-fill></b-icon-pencil-fill>
                        </b-button>
                        <b-button variant="danger" size="sm" @click="deleteItemChildren(p.id)">
                          <b-icon-trash-fill></b-icon-trash-fill>
                        </b-button>
                      </b-button-group>
                    </div>

                  </b-list-group-item>
                  <b-list-group-item>
                    <b-button v-if="actualChildrencategory.id!=0"
                      @click="subitemCreateCollapseState=true;cleanSubitem()" variant="primary">Agregar +</b-button>
                    <b-collapse id="collapse-create-childrencategory" v-model="subitemCreateCollapseState" class="mt-2">
                      <b-card>
                        <b-row>
                          <b-col>
                            <h4 class="card-text">Agregar Topping</h4>
                            <p>Busca aqui toppings de otros productos o crea uno nuevo mas abajo</p>
                            <vu-select :reduce="(x) => x.id" v-model="selectedTopping" :options="childrenOptions">
                            </vu-select>

                          </b-col>
                        </b-row>

                        <b-jumbotron class="my-3 py-3" v-if="!selectedTopping">
                          <b-row>
                            <b-col lg="12">
                              <p>Inserta aqui los datos para crear un nuevo topping</p>
                              <b-form-group label="Nombre">
                                <b-form-input v-model="actualChildren.name"
                                  placeholder="Si no existe inserta el nombre"></b-form-input>
                              </b-form-group>
                            </b-col>
                            <b-col lg="6">
                              <b-form-group label="Precio">
                                <b-form-input v-model.number="actualChildren.price" placeholder="Precio">
                                </b-form-input>
                              </b-form-group>
                            </b-col>
                            <b-col lg="6">
                              <b-form-group label="Maximo">
                                <b-form-input v-model.number="actualChildren.maxLimit" placeholder="Maximo">
                                </b-form-input>
                              </b-form-group>
                            </b-col>
                            <b-col xl="12" md="12" sm="12">
                              <b-form-group label="Producto">
                                <vu-select :reduce="(x) => x.id" v-model="actualChildren.product_id"
                                  :options="productOptions"></vu-select>
                              </b-form-group>
                            </b-col>
                            <b-col xl="12" md="12" sm="12">
                              <b-form-group label="Personalizador">
                                <vu-select :reduce="(x) => x.id" v-model="actualChildren.personalization_id"
                                  :options="personalizationsOptions"></vu-select>
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-jumbotron>
                        <b-row class="mt-3">
                          <b-col>
                            <b-button variant="success"
                              @click="createItemChildren(()=>subitemCreateCollapseState=false)">Aceptar</b-button>
                            <b-button variant="danger" @click="subitemCreateCollapseState=false;cleanSubitem()">Cancelar
                            </b-button>
                          </b-col>
                        </b-row>
                      </b-card>
                    </b-collapse>
                  </b-list-group-item>
                </b-list-group>
              </b-col>
            </b-row>

          </b-card>
        </b-col>
      </b-row>


    </b-container>
  </div>
</template>
<script>
import Nav from "../components/Nav.vue";
import axios from "axios";
import myUpload from "vue-image-crop-upload/upload-2.vue";
export default {
  name: "RappiItem",
  components: { Nav, myUpload },
  data() {
    return {
      statusoptions: [
        { value: true, text: "ACTIVO" },
        { value: false, text: "INACTIVO" },
      ],
      imgheaders: {
        Authorization: "",
      },
      imgparams: {},
      show: false,
      productChildren: [],
      childrenOptions: [],
      productChildrencategories: [],
      actualChildrencategory: {
        id: 0,
        name: "",
        maxQty: 0,
        minQty: 0,
        sorting_position: 1,
        status: true
      },
      actualProduct: {
        id: 0,
        sku: "",
        name: "",
        description: "",
        type: "PRODUCT",
        price: 0,
        imageUrl: "",
        availableFrom: null,
        availableTo: null,
        rappiIds: null,
        sortingPosition: 0,
        maxLimit: 0,
        category_id: null,
        category: {
          id: 0,
          name: "",
          minQty: 0,
          maxQty: 0,
          sortingPosition: 0
        },
        children: [],
        menu_id: 1,
        product_id: null,
        status: true
      },
      actualChildren: {
        id: 0,
        name: "",
        description: "",
        price: null,
        sku: "",
        maxLimit: null,
        sortingPosition: 0,
        type: "TOPPING",
        category_id: null,
        product_id: null,
        personalization_id: null,
        status: true,
        category: {
          id: 0,
          name: "",
          minQty: 0,
          maxQty: 0,
          sortingPosition: 0
        }
      },
      products: [],
      productOptions: [],
      rappiCategories: [],
      rappiChildrencategories: [],
      childrencategoriesOptions: [],
      childrencategoryCreateCollapseState: false,
      subitemCreateCollapseState: false,
      childrencategoriesadd: {
        search: "",
        result: {},
        filteredToppings: [],
      },
      selectedChildrencategory: null,
      selectedTopping: null,
      personalizations: [],
      personalizationsOptions: [],
      rappiCategoriesOptions: [],
    };
  },
  beforeRouteUpdate(to, from) {
    console.log(to);
    console.log(from);
    this.initData();
  },
  mounted() {
    this.initData();
  },
  watch: {
    actualChildrencategory(n) {
      this.filterProductChildren(n.id)
    }
  },
  methods: {
    initData() {
      this.imgheaders.Authorization =
        "Bearer " + localStorage.getItem("user-token");
      this.getAllProducts();
      this.getAllRappiCategories();
      this.getChildrenCategories()
      this.getPersonalizations()
      this.getAllChildren()
      if (this.$route.params.id > 0) {
        console.log("producto no nuevo");
        this.actualProduct.id = this.$route.params.id;
        this.getProduct();
        this.getProductChildren()
        this.getProductChildrencategories()
      }
    },
    getAllChildren() {
      let that = this
      axios.get("/rappi/menu/subitems")
        .then(function (response) {
          // that.productChildren = response.data
          let persfil = response.data
            .filter((pers) => {
              return pers.status;
            })
            .map((pers) => {
              return { id: pers.id, label: pers.id + " - " + pers.name };
            });
          let persfils = [{ id: null, label: "NINGUNO" }]
          persfils.push(...persfil)
          that.childrenOptions = persfils
        }).catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        })
    },
    getProductChildren() {
      let that = this
      axios.get("/rappi/menu/item/" + this.actualProduct.id + "/subitems")
        .then(function (response) {
          that.productChildren = response.data
          if (that.actualChildrencategory.id != 0) {
            that.filterProductChildren(that.actualChildrencategory.id)
          }
        }).catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        })
    },
    getPersonalizations() {
      let that = this
      axios.get("/personalizations")
        .then(function (response) {
          that.personalizations = response.data
          console.log(response.data)
          let persfil = response.data
            .filter((pers) => {
              return pers.status;
            })
            .map((pers) => {
              return { id: pers.id, label: pers.id + " - " + pers.name };
            });
          let persfils = [{ id: null, label: "NINGUNO" }]
          persfils.push(...persfil)
          that.personalizationsOptions = persfils
        }).catch(function (err) {
          console.log(err)
          that.makeToast("Error", err.request.response, "danger");
        })
    },
    filterProductChildren(n) {
      this.childrencategoriesadd.filteredToppings = this.productChildren.filter(w => w.category_id == n)
    },
    getChildrenCategories() {
      let that = this
      axios.get("/rappi/menu/children/categories")
        .then(function (response) {
          that.rappiChildrencategories = response.data
          let persfil = response.data
            .map((pers) => {
              return { id: pers.id, label: pers.name };
            });
          let persfils = []
          persfils.push(...persfil)
          that.childrencategoriesOptions = persfils;
        }).catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        })
    },

    getProductChildrencategories() {
      let that = this
      axios.get("/rappi/menu/item/" + this.actualProduct.id + "/categories")
        .then(function (response) {
          that.productChildrencategories = response.data
        }).catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        })
    },
    getAllProducts() {
      let that = this;
      axios
        .get("/products")
        .then(function (response) {
          that.products = response.data;
          let persfil = response.data
            .filter((pers) => {
              return pers.status;
            })
            .map((pers) => {
              return { id: pers.id, label: pers.clave + " - " + pers.ndescription };
            });
          let persfils = [{ id: null, label: "NINGUNO" }]
          persfils.push(...persfil)
          that.productOptions = persfils;
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    getAllRappiCategories() {
      let that = this;
      axios
        .get("/rappi/menu/categories")
        .then(function (response) {
          that.rappiCategories = response.data;
          let persfil = response.data
            .map((pers) => {
              return { id: pers.id, label: pers.name };
            });
          that.rappiCategoriesOptions = persfil;
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    editChildrencategory(c) {
      this.cleanChildrencategory()
      this.actualChildrencategory = c
      this.childrencategoryCreateCollapseState = true
    },
    editTopping(c) {
      this.cleanSubitem()
      this.actualChildren = c
      this.subitemCreateCollapseState = true
    },
    cleanChildrencategory() {
      this.actualChildrencategory =
      {
        id: 0,
        name: "",
        maxQty: 0,
        minQty: 0,
        sorting_position: 1,
        status: true
      }
    },
    cleanSubitem() {
      this.actualChildren =
      {
        id: 0,
        name: "",
        description: "",
        price: null,
        sku: "",
        maxLimit: null,
        sortingPosition: 0,
        type: "TOPPING",
        category_id: null,
        product_id: null,
        personalization_id: null,
        status: true,
        category: {
          id: 0,
          name: "",
          minQty: 0,
          maxQty: 0,
          sortingPosition: 0
        }

      }
    },
    createChildrencategory(f) {

      let that = this;
      if (this.selectedChildrencategory > 0) {
        axios.post("/rappi/menu/item/" + that.actualProduct.id + "/children/category/" + this.selectedChildrencategory).then((response) => {
          that.getChildrenCategories()
          that.getProductChildrencategories()
          that.getProductChildren()
          that.makeToast("Exito", response.data.id, "success");
          f()
          that.cleanChildrencategory()
        })
        return
      }

      if (this.actualChildrencategory.id > 0) {
        console.log("put Childrencategory");
        axios
          .put(
            "/rappi/menu/children/category", this.actualChildrencategory
          )
          .then(function (response) {
            that.getChildrenCategories()
            that.getProductChildrencategories()
            that.makeToast("Exito", response.data.id, "success");
            f()
            that.cleanChildrencategory()
          })
          .catch(function (err) {
            that.makeToast("Error", err.request.response, "danger");
          });
      } else {
        console.log("post Childrencategory");
        axios
          .post("/rappi/menu/children/category", this.actualChildrencategory)
          .then(function (response) {
            axios.post("/rappi/menu/item/" + that.actualProduct.id + "/children/category/" + response.data.id).then(() => {
              that.getChildrenCategories()
              that.getProductChildrencategories()
              that.makeToast("Exito", response.data.id, "success");
              f()
              that.cleanChildrencategory()
            })
          })
          .catch(function (err) {
            that.makeToast("Error", err.request.response, "danger");
          });
      }
    },
    deleteChildrencategory(category_id) {
      let that = this;
      axios.delete("/rappi/menu/item/" + this.actualProduct.id + "/children/category/" + category_id)
        .then((response) => {
          that.getProductChildren()
          that.getChildrenCategories()
          that.getProductChildrencategories()
          that.makeToast("Exito al borrar", response.data, "success");
        })
    },
    deleteItemChildren(subitem_id) {
      let that = this;
      axios.delete("/rappi/menu/children/category/" + this.actualChildrencategory.id + "/subitem/" + subitem_id)
        .then((response) => {
          that.getChildrenCategories()
          that.getProductChildrencategories()
          that.getProductChildren()
          that.makeToast("Exito al borrar", response.data, "success");

        })
    },
    createItemChildren(f) {
      let that = this;
      if (this.selectedTopping > 0) {
        axios.post("/rappi/menu/children/category/" + that.actualChildrencategory.id + "/subitem/" + this.selectedTopping).then((response) => {
          that.getChildrenCategories()
          that.getProductChildrencategories()
          that.getProductChildren()
          that.makeToast("Exito", response.data.id, "success");
          that.cleanSubitem()
          f()
          that.makeToast("Exito", response.data.id, "success");
        })
        return
      }

      let error = false
      let errormsj = ""
      if (this.actualChildren.personalization_id == null && this.actualChildren.product_id == null) {
        error = true
        errormsj += "Al menos debes seleccionar una personalizacion o un producto."
      }
      if (this.actualChildren.personalization_id != null && this.actualChildren.product_id != null) {
        error = true
        errormsj += "Solo debes seleccionar personalizacion o producto"
      }
      if (this.actualChildren.maxLimit < 0) {
        error |= true
        errormsj += "El limite maximo debe ser mayor a cero. "
      }
      if (error) {
        this.$bvModal.msgBoxOk(errormsj, {
          title: 'Error',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'success',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true
        })
        return
      }
      if (this.actualChildren.id > 0) {
        console.log("put productpers");
        console.log(this.actualChildren)
        axios
          .put(
            "/rappi/menu/subitem", this.actualChildren
          )
          .then(function (response) {
            that.getProductChildren()
            that.cleanSubitem()
            f()
            that.makeToast("Exito", response.data.id, "success");
          })
          .catch(function (err) {
            that.makeToast("Error", err.request.response, "danger");
          });
      } else {
        console.log("post productpers");
        axios
          .post("/rappi/menu/subitem", this.actualChildren)
          .then(function (response) {
            axios.post("/rappi/menu/children/category/" + that.actualChildrencategory.id + "/subitem/" + response.data.id)
              .then(() => {
                that.getProductChildren()
                that.cleanSubitem()
                f()
                that.makeToast("Exito", response.data.id, "success");
              })

          })
          .catch(function (err) {
            that.makeToast("Error", err.request.response, "danger");
          });
      }
    },
    cropSuccess(_, field) {
      console.log("-------- crop success --------");
      console.log(field);
    },

    cropUploadSuccess(jsonData, field) {
      console.log("-------- upload success --------");
      console.log(jsonData);
      console.log("field: " + field);
      this.getProduct();
    },

    cropUploadFail(status, field) {
      console.log("-------- upload fail --------");
      console.log(status);
      console.log("field: " + field);
    },
    getProduct() {
      let that = this;
      axios
        .get("/rappi/menu/item/" + this.actualProduct.id)
        .then(function (response) {
          that.actualProduct = response.data;
          that.actualChildren.item_id = response.data.id
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },

    updateProduct() {
      let that = this;

      if (this.actualProduct.id > 0) {
        axios
          .put("/rappi/menu/item", this.actualProduct)
          .then(function (response) {
            that.actualProduct = response.data;
            that.makeToast("Exito", "exito al guardar", "success");
            that.getProduct();
          })
          .catch(function (err) {
            that.makeToast("Error", err.request.response, "danger");
          });
      } else {
        axios
          .post("/rappi/menu/item", this.actualProduct)
          .then(function (response) {
            that.makeToast("Exito", "exito al crear, redirigiendo", "success");
            console.log(response.data);
            that.$router.push({
              name: "RappiItemById",
              params: { id: response.data.id },
            });
          })
          .catch(function (err) {
            console.log(err)
            that.makeToast("Error", err.request.response, "danger");
          });
      }
    },

    makeToast(title, content, variant) {
      this.$bvToast.toast(content, {
        title,
        variant,
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
  },
};
</script>
