<template>
  <div>
    <Nav></Nav>
    <b-overlay :show="loading" rounded="sm">
      <b-row>
        <b-col>
          <h1>Crear factura</h1>
        </b-col>
      </b-row>
      <b-row class="mx-3">
        <b-col lg="3" md="12">
          <b-form-group label="Sucursal">
            <vu-select :reduce="(x) => x.id" v-model="sucursal_id" :options="sucursales"></vu-select>
          </b-form-group>
        </b-col>
        <b-col lg="2" md="12" class="d-flex align-items-center" v-if="sucursal_id > 0">
          <b-form-group label="Fecha">
            <date-picker v-model="newFactura.Fecha" :config="options"></date-picker>
          </b-form-group>
        </b-col>
        <b-col lg="5" md="12" v-if="sucursal_id > 0">
          <b-form-group label="Cliente">
            <vu-select @search="searchClients" :reduce="(x) => x.id" v-model="newFactura.Receptor.UID"
              :options="catalogClients">
              <template slot="no-options">
                Escribe algo para buscar clientes....
              </template>
            </vu-select>
          </b-form-group>
        </b-col>
        <b-col lg="2" md="12" class="d-flex align-items-center" v-if="sucursal_id > 0">
          <b-button-group>
            <b-button variant="success" @click="$refs.nuevoCliente.createClient()">Agregar</b-button>
            <b-button variant="info" @click="$refs.nuevoCliente.editClient(newFactura.Receptor.UID)"
              v-if="newFactura.Receptor.UID != '' && newFactura.Receptor.UID != null">Editar</b-button>
          </b-button-group>
        </b-col>

      </b-row>
      <div v-if="sucursal_id > 0">
        <b-row class="mx-3">

          <b-col lg="6" md="12">
            <b-form-group label="Uso CFDI">
              <vu-select :reduce="(x) => x.id" v-model="newFactura.UsoCFDI" :options="catalogUso"></vu-select>
            </b-form-group>
          </b-col>
          <b-col lg="2" md="12">
            <b-form-group label="Serie">
              <vu-select :reduce="(x) => x.id" v-model="newFactura.Serie" :options="catalogSeries"></vu-select>
            </b-form-group>
          </b-col>
          <b-col lg="4" md="12">
            <b-form-group label="Metodo">
              <vu-select :reduce="(x) => x.id" v-model="newFactura.MetodoPago" :options="catalogMetodoPago"></vu-select>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="12">
            <b-form-group label="Moneda">
              <vu-select :reduce="(x) => x.id" v-model="newFactura.Moneda" :options="catalogMoneda"></vu-select>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="12">
            <b-form-group label="Forma de pago">
              <vu-select :reduce="(x) => x.id" v-model="newFactura.FormaPago" :options="catalogFormaPago"></vu-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-jumbotron class="p-3 mx-3">
          <b-row>
            <b-col>
              <h3>Concepto</h3>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="2" md="12">
              <b-form-group label="Cantidad">
                <b-form-input v-model="concepto.Cantidad"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col lg="2" md="12">
              <b-form-group label="Clave SAT">
                <b-form-input v-model="concepto.ClaveProdServ"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col lg="8" md="12">
              <b-form-group label="Descripcion">
                <b-form-input v-model="concepto.Descripcion"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col lg="3" md="12">
              <b-form-group label="Unidad">
                <vu-select :reduce="(x) => x.id" v-model="concepto.ClaveUnidad" :options="catalogUnidades"></vu-select>
              </b-form-group>
            </b-col>
            <b-col lg="3" md="12">
              <b-form-group label="Precio">
                <b-form-input type="number" v-model.number="concepto.ValorUnitario"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col lg="3" md="12">
              <b-form-group label="Subtotal">
                {{ concepto.ValorUnitario * concepto.Cantidad }}
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <h4>Impuestos</h4>
              <b-row>
                <b-col lg="6" md="12">
                  <b-card class="p-0 my-3">
                    <b-row>
                      <b-col>
                        <b-form-checkbox v-model="concepto.Impuestos.Traslados[0].Active" :value="true"
                          :unchecked-value="false">
                          <h5>IVA Traslado</h5>
                        </b-form-checkbox>

                      </b-col>
                    </b-row>
                    <b-row v-if="concepto.Impuestos.Traslados[0].Active">
                      <b-col lg="3" md="12">
                        <b-form-group label="Impuesto">
                          <vu-select disabled :reduce="(x) => x.id" v-model="concepto.Impuestos.Traslados[0].Impuesto"
                            :options="catalogImpuestos"></vu-select>
                        </b-form-group>
                      </b-col>
                      <b-col lg="3" md="12">
                        <b-form-group label="Factor">
                          <b-form-input type="number"
                            v-model.number="concepto.Impuestos.Traslados[0].TasaOCuota"></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col lg="2" md="12">
                        <b-form-group label="Base">
                          {{ concepto.Cantidad * concepto.ValorUnitario }}
                        </b-form-group>
                      </b-col>
                      <b-col lg="2" md="12">
                        <b-form-group label="Total">
                          {{ concepto.Cantidad * concepto.ValorUnitario * concepto.Impuestos.Traslados[0].TasaOCuota }}
                        </b-form-group>
                      </b-col>
                    </b-row>

                  </b-card>
                </b-col>
                <b-col lg="6" md="12">
                  <b-card class="p-0 my-3">
                    <b-row>
                      <b-col>
                        <b-form-checkbox v-model="concepto.Impuestos.Traslados[1].Active" :value="true"
                          :unchecked-value="false">
                          <h5>IEPS Traslado</h5>
                        </b-form-checkbox>

                      </b-col>
                    </b-row>
                    <b-row v-if="concepto.Impuestos.Traslados[1].Active">
                      <b-col lg="3" md="12">
                        <b-form-group label="Impuesto">
                          <vu-select disabled :reduce="(x) => x.id" v-model="concepto.Impuestos.Traslados[1].Impuesto"
                            :options="catalogImpuestos"></vu-select>
                        </b-form-group>
                      </b-col>
                      <b-col lg="3" md="12">
                        <b-form-group label="Factor">
                          <b-form-input type="number"
                            v-model.number="concepto.Impuestos.Traslados[1].TasaOCuota"></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col lg="2" md="12">
                        <b-form-group label="Base">
                          {{ concepto.Cantidad * concepto.ValorUnitario }}
                        </b-form-group>
                      </b-col>
                      <b-col lg="2" md="12">
                        <b-form-group label="Total">
                          {{ concepto.Cantidad * concepto.ValorUnitario * concepto.Impuestos.Traslados[1].TasaOCuota }}
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
                <b-col lg="6" md="12">
                  <b-card class="p-0 my-3">
                    <b-row>
                      <b-col>
                        <b-form-checkbox v-model="concepto.Impuestos.Retenidos[0].Active" :value="true"
                          :unchecked-value="false">
                          <h5>ISR Retencion</h5>
                        </b-form-checkbox>

                      </b-col>
                    </b-row>
                    <b-row v-if="concepto.Impuestos.Retenidos[0].Active">
                      <b-col lg="3" md="12">
                        <b-form-group label="Impuesto">
                          <vu-select disabled :reduce="(x) => x.id" v-model="concepto.Impuestos.Retenidos[0].Impuesto"
                            :options="catalogImpuestos"></vu-select>
                        </b-form-group>
                      </b-col>
                      <b-col lg="3" md="12">
                        <b-form-group label="Factor">
                          <b-form-input type="number"
                            v-model.number="concepto.Impuestos.Retenidos[0].TasaOCuota"></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col lg="2" md="12">
                        <b-form-group label="Base">
                          {{ concepto.Cantidad * concepto.ValorUnitario }}
                        </b-form-group>
                      </b-col>
                      <b-col lg="2" md="12">
                        <b-form-group label="Total">
                          {{ concepto.Cantidad * concepto.ValorUnitario * concepto.Impuestos.Retenidos[0].TasaOCuota }}
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
              </b-row>
              <b-row class="my-3">
                <b-col>
                  <b-button variant="success" @click="addConceptoToFactura">Agregar Concepto +</b-button>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-table striped hover :items="newFactura.Conceptos" :fields="conceptosFields" responsive>
                    <template #cell(buttons)="data">
                      <b-button-group>
                        <b-button variant="danger"
                          @click="deleteConcepto(data.item.Id)"><b-icon-trash></b-icon-trash></b-button>
                      </b-button-group>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-jumbotron>
        <b-row class="d-flex align-items-center">
          <b-col lg="8" md="12">
            <b-form-group label="Comentarios">
              <b-form-textarea id="textarea" v-model="newFactura.Comentarios" placeholder="Enter something..." rows="3"
                max-rows="8"></b-form-textarea>
            </b-form-group>

          </b-col>
          <b-col lg="4" md="12">
            <b-list-group>
              <b-list-group-item class="d-flex justify-content-between align-items-center">
                Subtotal
                <b>$ {{ this.totals.subtotal }}</b>
              </b-list-group-item>
              <b-list-group-item class="d-flex justify-content-between align-items-center">
                +IVA
                <b>$ {{ this.totals.ivaTraslado }}</b>
              </b-list-group-item>
              <b-list-group-item class="d-flex justify-content-between align-items-center">
                +IEPS
                <b>$ {{ this.totals.iepsTraslado }}</b>
              </b-list-group-item>
              <b-list-group-item class="d-flex justify-content-between align-items-center">
                -ISR
                <b>$ {{ this.totals.isrRetencion }}</b>
              </b-list-group-item>
              <b-list-group-item class="d-flex justify-content-between align-items-center">
                <B>TOTAL</B>
                <b>$ {{ this.totals.total }}</b>
              </b-list-group-item>
            </b-list-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button variant="success" @click="timbrarFactura()"> Timbrar</b-button>
          </b-col>
        </b-row>
      </div>
      <template #overlay>
        <div class="text-center">
          <b-icon icon="stopwatch" font-scale="3" animation="cylon"></b-icon>
          <p>Estamos creando tu factura, esto toma algun tiempo</p>
        </div>
      </template>
    </b-overlay>
    <CreateFacturacionCliente ref="nuevoCliente" :sucursal_id="sucursal_id" @updated="getClients">
    </CreateFacturacionCliente>
  </div>
</template>

<script>
import Nav from "../components/Nav.vue";
import axios from "axios";

import datePicker from "vue-bootstrap-datetimepicker";
import "bootstrap/dist/css/bootstrap.css";
import "@fortawesome/fontawesome-free/css/all.css";
import CreateFacturacionCliente from "../components/CreateFacturacionCliente.vue"
export default {
  name: "factura",
  components: { Nav, CreateFacturacionCliente, datePicker },
  data() {
    return {
      options: {
        format: "YYYY-MM-DDTHH:mm:ss",
        useCurrent: true,
      },
      loading: false,
      sucursal_id: null,
      catalogUso: [],
      catalogFormaPago: [],
      catalogMetodoPago: [],
      catalogMoneda: [],
      catalogPais: [],
      catalogImpuestos: [],
      catalogSeries: [],
      catalogUnidades: [],
      rawCatalogUnidades: [],
      catalogClients: [],
      sucursales: [],
      newFactura: {
        Receptor: {
          UID: ""
        },
        TipoDocumento: "factura",
        Conceptos: [],
        UsoCFDI: "",
        Serie: null,
        FormaPago: "03",
        MetodoPago: "PUE",
        Moneda: "MXN",
        EnviarCorreo: false,
        Comentarios: "",
        Fecha: ""
      },
      conceptosFields: [
        { key: "Id", label: "ID", sortable: true, sortDirection: "asc" },
        { key: "Cantidad", label: "Cantidad", sortable: true },
        { key: "Unidad", label: "Unidad", sortable: true },
        { key: "Descripcion", label: "Descripcion", sortable: true },
        { key: "Subtotal", label: "Subtotal", sortable: true },
        { key: "buttons", label: "" },
      ],
      concepto: {
        Id: 0,
        Subtotal: 0,
        ClaveProdServ: "",
        Cantidad: 1,
        ClaveUnidad: "",
        Unidad: "",
        ValorUnitario: 0,
        Descripcion: "",
        Impuestos: {
          Traslados: [
            {
              Active: false,
              Base: 0,
              Impuesto: "002",
              TipoFactor: "Tasa",
              TasaOCuota: 0,
              Importe: 0
            },
            {
              Active: false,
              Base: 0,
              Impuesto: "003",
              TipoFactor: "Tasa",
              TasaOCuota: 0,
              Importe: 0
            }
          ],
          Retenidos: [
            {
              Active: false,
              Base: 0,
              Impuesto: "001",
              TipoFactor: "Tasa",
              TasaOCuota: 0,
              Importe: 0
            }
          ]
        }
      },


    };
  },
  computed: {
    maxId() {
      let val = Math.max(...this.newFactura.Conceptos.map(o => o.Id))
      if (val == -Infinity) {
        return 0
      } else {
        return val
      }

    },
    totals() {
      let subtotal = 0
      let ivaTraslado = 0
      let iepsTraslado = 0
      let isrRetencion = 0
      let total = 0
      this.newFactura.Conceptos.forEach(element => {
        subtotal += element.Subtotal
        ivaTraslado += element.Impuestos.Traslados[0].Importe
        iepsTraslado += element.Impuestos.Traslados[1].Importe
        isrRetencion += element.Impuestos.Retenidos[0].Importe
      });
      total = subtotal + ivaTraslado + iepsTraslado - isrRetencion
      return { subtotal: subtotal, ivaTraslado, iepsTraslado, isrRetencion, total }
    }
  },
  mounted() {
    this.getSucursales()
    if (this.sucursal_id > 0) {
      // this.getClients()
      this.getCatalogFormaPago()
      this.getCatalogMetodoPago()
      this.getCatalogMoneda()
      this.getCatalogUso()
      this.getCatalogImpuestos()
      this.getCatalogPais()
      this.getCatalogSeries()
      this.getCatalogUnidades()
    }

  },
  watch: {
    sucursal_id() {
      if (this.sucursal_id > 0) {
        // this.getClients()
        this.getCatalogFormaPago()
        this.getCatalogMetodoPago()
        this.getCatalogMoneda()
        this.getCatalogUso()
        this.getCatalogImpuestos()
        this.getCatalogPais()
        this.getCatalogSeries()
        this.getCatalogUnidades()

      }
    }
  },
  methods: {
    deleteConcepto(id) {
      let conc = []
      this.newFactura.Conceptos.forEach((x) => {

        if (x.Id != id) {
          conc.push(x)
        }
      })
      this.newFactura.Conceptos = conc
    },
    addConceptoToFactura() {

      this.rawCatalogUnidades.forEach((x) => {
        if (x.key == this.concepto.ClaveUnidad) {
          this.concepto.Unidad = x.name
        }
      })
      this.concepto.Subtotal = this.concepto.Cantidad * this.concepto.ValorUnitario

      this.concepto.Impuestos.Traslados[0].Base = parseFloat((this.concepto.Subtotal).toFixed(6))
      this.concepto.Impuestos.Traslados[0].Importe = parseFloat((this.concepto.Subtotal * this.concepto.Impuestos.Traslados[0].TasaOCuota).toFixed(6))
      this.concepto.Impuestos.Traslados[1].Base = parseFloat((this.concepto.Subtotal).toFixed(6))
      this.concepto.Impuestos.Traslados[1].Importe = parseFloat((this.concepto.Subtotal * this.concepto.Impuestos.Traslados[1].TasaOCuota).toFixed(6))

      this.concepto.Impuestos.Retenidos[0].Base = parseFloat((this.concepto.Subtotal).toFixed(6))
      this.concepto.Impuestos.Retenidos[0].Importe = parseFloat((this.concepto.Subtotal * this.concepto.Impuestos.Retenidos[0].TasaOCuota).toFixed(6))

      this.concepto.Id = this.maxId + 1
      this.newFactura.Conceptos.push(this.concepto)
      this.concepto = {
        Subtotal: 0,
        ClaveProdServ: "",
        Cantidad: 1,
        ClaveUnidad: "",
        Unidad: "",
        ValorUnitario: 0,
        Descripcion: "",
        Impuestos: {
          Traslados: [
            {
              Active: false,
              Base: 0,
              Impuesto: "002",
              TipoFactor: "Tasa",
              TasaOCuota: 0,
              Importe: 0
            },
            {
              Active: false,
              Base: 0,
              Impuesto: "003",
              TipoFactor: "Tasa",
              TasaOCuota: 0,
              Importe: 0
            }
          ],
          Retenidos: [
            {
              Active: false,
              Base: 0,
              Impuesto: "001",
              TipoFactor: "Tasa",
              TasaOCuota: 0,
              Importe: 0
            }
          ]
        }
      }
    },
    timbrarFactura() {
      let that = this;
      this.loading = true
      console.log(this.newFactura)
      // let fact=this.newFactura
      // let nConc=[]
      // fact.Conceptos.forEach((x)=>{
      //   x.Impuestos.Traslados.forEach((t,i)=>{
      //     if(t.Active){

      //     }
      //   })
      //   x.Impuestos.Retenidos.forEach((r,i)=>{

      //   })
      // })

      axios
        .post("/company/1/sucursal/" + this.sucursal_id + "/factura", this.newFactura)
        .then(function (response) {
          that.makeToast(
            "Exito",
            "factura " + response.data.uid + " creada con exito",
            "success"
          );
          that.loading = false
          this.$router.push("/facturas")
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
          that.loading = false
        });
    },
    getSucursales() {
      let that = this;
      axios
        .get("/sucursals")
        .then(function (response) {
          let sucursales = response.data.map((x) => {
            return { id: x.id, label: x.nombre }
          })
          that.sucursales = sucursales
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    searchClients(search, loading) {
      this.getClients(null, search, loading)
    },
    getClients(e, search, l) {
      let that = this;
      console.log(e)
      let xtraSearch = ""
      if (search.length > 0) {
        xtraSearch = "?search=" + search
      }
      axios
        .get("/company/1/sucursal/" + this.sucursal_id + "/facturacion/clientes" + xtraSearch)
        .then(function (response) {
          let uso = response.data.map((x) => { return { id: x.uid, label: x.rfc + " - " + x.razon_social } }).filter(x => x.id.length > 0)
          that.catalogClients = uso
          l(false)
          if (e != '' && e != null) {
            that.newFactura.Receptor.UID = e
          }
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    getCatalogUso() {
      let that = this;
      axios
        .get("/company/1/sucursal/" + this.sucursal_id + "/sat/catalog/usocfdi")
        .then(function (response) {
          let uso = response.data.map((x) => { return { id: x.key, label: x.name } })
          that.catalogUso = uso
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    getCatalogUnidades() {
      let that = this;
      axios
        .get("/company/1/sucursal/" + this.sucursal_id + "/sat/catalog/unidadmedida")
        .then(function (response) {
          let uso = response.data.map((x) => { return { id: x.key, label: x.key + " - " + x.name } })
          that.catalogUnidades = uso
          that.rawCatalogUnidades = response.data
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    getCatalogFormaPago() {
      let that = this;
      axios
        .get("/company/1/sucursal/" + this.sucursal_id + "/sat/catalog/formapago")
        .then(function (response) {
          let fp = response.data.filter(x => x.key != "").map((x) => { return { id: x.key, label: x.name } })
          that.catalogFormaPago = fp
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    getCatalogMetodoPago() {
      let that = this;
      axios
        .get("/company/1/sucursal/" + this.sucursal_id + "/sat/catalog/metodopago")
        .then(function (response) {
          let mp = response.data.map((x) => { return { id: x.key, label: x.name } })
          that.catalogMetodoPago = mp
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    getCatalogMoneda() {
      let that = this;
      axios
        .get("/company/1/sucursal/" + this.sucursal_id + "/sat/catalog/moneda")
        .then(function (response) {
          let mon = response.data.map((x) => { return { id: x.key, label: x.name } })
          that.catalogMoneda = mon
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    getCatalogImpuestos() {
      let that = this;
      axios
        .get("/company/1/sucursal/" + this.sucursal_id + "/sat/catalog/impuestos")
        .then(function (response) {
          let mon = response.data.map((x) => { return { id: x.key, label: x.name } })
          that.catalogImpuestos = mon
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    getCatalogPais() {
      let that = this;
      axios
        .get("/company/1/sucursal/" + this.sucursal_id + "/sat/catalog/pais")
        .then(function (response) {
          that.catalogPais = response.data;
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    getCatalogSeries() {
      let that = this;
      axios
        .get("/company/1/sucursal/" + this.sucursal_id + "/facturacion/series")
        .then(function (response) {
          let series = response.data.map((x) => { return { id: x.SerieID, label: x.SerieName } })
          that.catalogSeries = series
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    makeToast(title, content, variant) {
      this.$bvToast.toast(content, {
        title,
        variant,
        appendToast: true,
      });
    },
  },
};
</script>
