<template>
  <div>
    <Nav></Nav>
    <b-container>

      <b-card class="mt-3">
        <b-row class="px-3" align-v="center">
          <b-col class="ml--2">
            <h1 class="mb-0">
              Sucursal: {{ this.actualSucursal.nombre }}
            </h1>
            <div v-if="this.actualSucursal.status">
              <span class="text-success">● </span>
              <small>Activa</small>
            </div>
            <div v-else>
              <span class="text-danger">● </span>
              <small>Inactiva</small>
            </div>

          </b-col>
          <b-col md="auto">
            <base-button class="mx-3" @click="getSucursal" type="primary"
              size="sm"><b-icon-arrow-clockwise></b-icon-arrow-clockwise></base-button>
          </b-col>
        </b-row>
        <hr class="my-1" />
        <b-row>
          <b-col>
            <base-button type="primary" class="my-1" size="sm">Editar</base-button>
            <base-button  class="my-1" :disabled="!this.actualSucursal.status" @click="sendRemoteAction('updatePosVersion')" type="primary" size="sm">Actualizar version POS</base-button>
            <base-button  class="my-1" :disabled="!this.actualSucursal.status" @click="sendRemoteAction('updateInfo')" type="primary" size="sm">Actualizar Info POS</base-button>
          </b-col>
        </b-row>
        <hr class="my-1" />
        <b-row>
          <b-col>
            <h3 style="text-align: center;"></h3>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6" cols="12">
            <p>
              Version: {{ this.actualSucursal.version }}<br>
              Version de Base de datos: {{ this.actualSucursal.database_version }}<br>
              Estatus de migracion de base de datos: {{ (this.actualSucursal.database_dirty == 0 ? 'SALUDABLE' : 'NO SALUDABLE')
              }}<br>
              Ultima actualización: {{ this.actualSucursal.version_last_update }}
            </p>
          </b-col>
          <b-col md="6" cols="12">
            <p>
              ID Rappi: {{ this.actualSucursal.rappi_id }} <br>
              Menu Rappi: {{ this.actualSucursal.rappi_menu_id }}<br>
              ID Uber: {{ this.actualSucursal.uber_store_id }}<br>
              Menu Uber: {{ this.actualSucursal.uber_menu_id }}
            </p>
          </b-col>
        </b-row>
      </b-card>


      <b-card class="mt-3" >
        <b-row class="px-3" align-v="center">
          <b-col class="ml--2">
            <h1 class="mb-0">
              Uber Eats
            </h1>
            <div v-if="this.actualSucursal.uber_store_id==null">
              <span class="text-warning">● </span>
              <small>No Configurado</small>
            </div>
            <div v-else-if="this.eatsStatus.status == 'ONLINE'">
              <span class="text-success">● </span>
              <small>Activa</small>
            </div>
            <div v-else-if="this.eatsStatus.status == 'OFFLINE'">
              <span class="text-danger">● </span>
              <small>INACTIVA Razon: {{ this.eatsStatus.offlineReason}} {{ this.eatsStatus.paused_until}}</small>
            </div>
            <div v-else-if="this.eatsStatus.status == ''">
              <span class="text-warning">● </span>
              <small>Sin Informacion</small>
            </div>
           
          </b-col>
          <b-col md="auto">
            <base-button v-if="this.actualSucursal.uber_store_id!=null" class="mx-3" @click="getUberInfo" type="primary"
              size="sm"><b-icon-arrow-clockwise></b-icon-arrow-clockwise></base-button>
          </b-col>
        </b-row>
        <hr class="my-1" />
        <div v-if="this.actualSucursal.uber_store_id!=null">
          <b-row>
            <b-col>
              <base-button  class="my-1"  @click="sendUberMenu" type="primary" size="sm">Enviar Menu Uber</base-button>
            </b-col>
          </b-row>
          <hr class="my-1" />

          <b-row >
            <b-col>
              <b-alert :show="!this.uberInfo.pos_data.integration_enabled" variant="warning">La integración no se encuentra activa para esta tienda. Solicita a tu contacto de soporte que active esta tienda.</b-alert>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12" cols="12">
              <p>
                Nombre: {{ this.uberInfo.name }}<br>
                ID de Tienda: {{ this.uberInfo.store_id }}<br>
                Direccion: {{ this.uberInfo.location.address }}<br>
                Tiempo Promedio Prep: {{ this.uberInfo.avg_prep_time }}<br>
                Status: {{ this.uberInfo.status }}<br>
                Status Integración: {{ this.uberInfo.pos_data.integration_enabled }}<br>
                Url web: <a :href="uberInfo.web_url">{{ this.uberInfo.web_url }}</a>
                
              </p>
              <h4>De la integracion</h4>
              <p>
                Auto Aceptacion: <b-icon v-if="this.uberIntegrationInfo.auto_accept_enabled" icon="check-square" variant="success"></b-icon> <b-icon v-else icon="x-circle" variant="danger"></b-icon><br>
                Requiere Aceptacion Manual: <b-icon v-if="this.uberIntegrationInfo.require_manual_acceptance" icon="check-square" variant="success"></b-icon> <b-icon v-else icon="x-circle" variant="danger"></b-icon><br>
                Integración habilitada: <b-icon v-if="this.uberIntegrationInfo.integration_enabled" icon="check-square" variant="success"></b-icon> <b-icon v-else icon="x-circle" variant="danger"></b-icon><br>
                Status: {{ this.uberIntegrationInfo.online_status }}<br>
                Order Release Enabled: <b-icon v-if="this.uberIntegrationInfo.order_release_enabled" icon="check-square" variant="success"></b-icon> <b-icon v-else icon="x-circle" variant="danger"></b-icon><br>
                Pos integration enabled: <b-icon v-if="this.uberIntegrationInfo.pos_integration_enabled" icon="check-square" variant="success"></b-icon> <b-icon v-else icon="x-circle" variant="danger"></b-icon><br>
              </p>
            </b-col>
          </b-row>
        </div>
        
      </b-card>
      <b-card class="mt-3" >
        <b-row class="px-3" align-v="center">
          <b-col class="ml--2">
            <h1 class="mb-0">
              Rappi
            </h1>
            <div v-if="this.actualSucursal.rappi_id==null || this.actualSucursal.rappi_id<=0">
              <span class="text-warning">● </span>
              <small>No Configurado</small>
            </div>
            <div v-else-if="this.eatsStatus.status == 'ONLINE'">
              <span class="text-success">● </span>
              <small>Configuracion correcta</small>
            </div>
          </b-col>
          <b-col md="auto">
            <base-button v-if="this.actualSucursal.rappi_id!=null && this.actualSucursal.rappi_id!=0" class="mx-3" @click="getRappiInfo();getRappiMenuStatus();" type="primary"
              size="sm"><b-icon-arrow-clockwise></b-icon-arrow-clockwise></base-button>
          </b-col>
        </b-row>
        <hr class="my-1" />
        <div v-if="this.actualSucursal.rappi_id!=null && this.actualSucursal.rappi_id!=0">
          <b-row>
            <b-col>
              <base-button  class="my-1"  @click="$router.push('/rappi/stores')" type="primary" size="sm">Enviar Menu Rappi</base-button>
            </b-col>
          </b-row>
          <hr class="my-1" />
          <b-row >
            <b-col>
              <h3 style="text-align: center;"></h3>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12" cols="12">
              <p>
                Menu Status: {{ this.rappiMenuStatus }}<br>
                Codigo Check In: {{ this.rappiCheckInCode.code }}<br>
                Codigo Vence: {{ this.rappiCheckInCode.expired_at }}<br>
                Url web: <a :href="'https://www.rappi.com.mx/restaurantes/'+this.actualSucursal.rappi_id">{{ 'https://www.rappi.com.mx/restaurantes/'+this.actualSucursal.rappi_id }}</a>
              </p>
            </b-col>
          </b-row>
        </div>
      </b-card>
      <b-card class="mt-3" >
        <b-row class="px-3" align-v="center">
          <b-col class="ml--2">
            <h1 class="mb-0">
              Configuraciones
            </h1>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <p>
              companyName: {{ cfgs.companyName.param1 }}<br>
              razonSocial: {{ cfgs.razonSocial.param1 }}<br>
              regimenFiscal: {{ cfgs.regimenFiscal.param1 }}<br>
              rfc: {{ cfgs.rfc.param1 }}<br>
              direccionMatriz1: {{ cfgs.direccionMatriz1.param1 }}<br>
              direccionMatriz2: {{ cfgs.direccionMatriz2.param1 }}<br>
              direccionMatriz3: {{ cfgs.direccionMatriz3.param1 }}<br>
              sucursalName: {{ cfgs.sucursalName.param1 }}<br>
              direccionSucursal1: {{ cfgs.direccionSucursal1.param1 }}<br>
              direccionSucursal2: {{ cfgs.direccionSucursal2.param1 }}<br>
              direccionSucursal3: {{ cfgs.direccionSucursal3.param1 }}<br>
              maxCashPaydesk: {{ cfgs.maxCashPaydesk.param1 }}<br>
              ticketLastLine: {{ cfgs.ticketLastLine.param1 }}<br>
            </p>
          </b-col>
        </b-row>
      </b-card>
    </b-container>
  </div>
</template>
<script>
import Nav from "../components/Nav.vue";
import axios from "axios";
import _ from 'lodash';

export default {
  name: "Sucursal",
  components: { Nav },
  data() {
    return {
      configs:[],
      cfgs:{
        companyName:{param1:"",param2:"",param3:"",param4:""},
        direccionMatriz1:{param1:"",param2:"",param3:"",param4:""},
        direccionMatriz2:{param1:"",param2:"",param3:"",param4:""},
        direccionMatriz3:{param1:"",param2:"",param3:"",param4:""},
        direccionSucursal1:{param1:"",param2:"",param3:"",param4:""},
        direccionSucursal2:{param1:"",param2:"",param3:"",param4:""},
        direccionSucursal3:{param1:"",param2:"",param3:"",param4:""},
        maxCashPaydesk:{param1:"",param2:"",param3:"",param4:""},
        razonSocial:{param1:"",param2:"",param3:"",param4:""},
        rfc:{param1:"",param2:"",param3:"",param4:""},
        ticketLastLine:{param1:"",param2:"",param3:"",param4:""},
        sucursalName:{param1:"",param2:"",param3:"",param4:""},
        regimenFiscal:{param1:"",param2:"",param3:"",param4:""},

      },
      rappiMenuStatus:"",
      actualSucursal: {
        id: 0,
        description: "",
        code: "",
        name: "",
        unit: "",
        base_portion: 1
      },
      eatsStatus: {
        status: "",
        offlineReason: ""
      },
      rappiCheckInCode:{
        code:"",
        created_at:"",
        expired_at:"",
        updated_at:""
      },
      uberIntegrationInfo:{
        store_id: "",
        pos_integration_enabled: false,
        online_status: "",
        partner_store_id: "",
        order_release_enabled: false,
        auto_accept_enabled: false,
        order_manager_client_id: "",
        integration_enabled: false
      },
      uberInfo: {
        name: "",
        store_id: "",
        location: {
          address: "",
          address_2: "",
          city: "",
          country: "",
          postal_code: "",
          state: "",
          latitude: 0,
          longitude: 0
        },
        contact_emails: [
        ],
        raw_hero_url: "",
        price_bucket: "",
        avg_prep_time: 0,
        status: "",
        partner_store_id: "",
        timezone: "",
        pos_data: {
          integration_enabled: false,
          order_manager_client_id: ""
        },
        merchant_store_id: "",
        web_url: ""
      }
    };
  },
  beforeRouteUpdate(to, from) {
    console.log(to);
    console.log(from);
    this.initData();
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      console.log("creando");
      console.log(this.$route.params.id);
      if (this.$route.params.id > 0) {
        console.log("producto no nuevo");
        this.actualSucursal.id = this.$route.params.id;
        this.getSucursal();
      }
      console.log("termina creacion");
      this.getRestaurantConfigs()
    },
    getRestaurantConfigs(){
      let that=this
      axios.get("/restaurant/"+this.actualSucursal.id+"/config")
      .then(function(res){
        that.configs=res.data
        _.forOwn(that.cfgs, function(value, key) { 
          console.log(key+" "+value)
          let res=_.find(that.configs,function(n){
            return n.id==key
          })
          if(res!=undefined){
            that.cfgs[key].param1=res.param1
            that.cfgs[key].param2=res.param2
            that.cfgs[key].param3=res.param3
          }
          console.log(res)
          
        } );
      }).catch(function(e){
        console.error(e)
      })
    },
    getUberInfo() {
      let that = this;
      axios
        .get("/uber/store/" + this.actualSucursal.uber_store_id+"/status")
        .then(function (response) {
          that.eatsStatus = response.data;
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
        axios
        .get("/uber/store/" + this.actualSucursal.uber_store_id+"/info")
        .then(function (response) {
          that.uberInfo = response.data;
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
        axios
        .get("/uber/store/" + this.actualSucursal.uber_store_id+"/integration/status")
        .then(function (response) {
          console.log("getuberposinfo")
          console.log(response.data)
          that.uberIntegrationInfo = response.data;
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    getRappiInfo() {
      console.log("getRappiInfo")
      let that = this;
      axios
        .post("/sucursal/" + this.actualSucursal.id+"/rappi/checkincode")
        .then(function (response) {
          that.rappiCheckInCode = response.data;
          console.log(response.data)
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    getRappiMenuStatus() {
      console.log("getRappiMenuStatus")
      let that = this;
      axios
        .get("/sucursal/" + this.actualSucursal.id+"/rappi/status")
        .then(function (response) {
          that.rappiMenuStatus = response.data;
          console.log(response.data)
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    getSucursal() {
      let that = this;
      axios
        .get("/sucursal/" + this.actualSucursal.id)
        .then(function (response) {
          that.actualSucursal = response.data;
          if (that.actualSucursal.uber_store_id!=null){
            that.getUberInfo()
          }
          if (that.actualSucursal.rappi_id>0){
              that.getRappiInfo()
              that.getRappiMenuStatus()
            }
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    updateProduct() {
      let that = this;
      if (this.actualSucursal.id > 0) {
        axios
          .put("/supply/" + this.actualSucursal.id, this.actualSucursal)
          .then(function (response) {
            that.actualSucursal = response.data;
            that.makeToast("Exito", "exito al guardar", "success");
            that.getSucursal();
          })
          .catch(function (err) {
            that.makeToast("Error", err.request.response, "danger");
          });
      } else {
        axios
          .post("/supply", this.actualSucursal)
          .then(function (response) {
            that.makeToast("Exito", "exito al crear, redirigiendo", "success");
            console.log(response.data);
            that.$router.push({
              name: "Sucursal",
              params: { id: response.data.id },
            });
          })
          .catch(function (err) {
            that.makeToast("Error", err.request.response, "danger");
          });
      }
    },
    sendUberMenu() {
      let that = this
      axios
        .put("/sucursal/" + this.actualSucursal.id + "/uber/uploadmenu", null)
        .then(function () {
          that.makeToast("Exito", "EXITO AL SUBIR EL MENU", "success");
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });

    },
    sendRappiMenu() {
      let that = this
      axios
        .post("/sucursal/" + this.actualSucursal.id + "/rappi/sendmenu", null)
        .then(function () {
          that.makeToast("Exito", "EXITO AL SUBIR EL MENU", "success");
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });

    },
    sendRemoteAction(actiontype) {
      let that = this
      axios
        .get("/sucursal/" + this.actualSucursal.id + "/action/" + actiontype)
        .then(function () {
          that.makeToast("Exito", "EXITO AL enviar la accion", "success");
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });

    },
    makeToast(title, content, variant) {
      this.$bvToast.toast(content, {
        title,
        variant,
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
  },
};
</script>
