<template>
    <div>
      Selecciona alguna de las opciones en los paneles superiores para poder tomar mas acciones:
      <ul>
        <li>Menus: Vision general de menus dentro de la tienda.</li>
        <li>Categorias: Categorias disponibles a lo largo de todos los menus.</li>
        <li>Articulos: Los productos o items que estan disponibles para seleccionar en las categorias.</li>
        <li>Grupos de Modificadores: Modificadores que puedes seleccionar dentro de cada uno de los items.</li>
      </ul>
    </div>
  </template>
  <script>
  import axios from "axios";
  export default {
    name: "ComponentUberOverview",
    data() {
      return {
        byPage: 20,
        page: 1,
        productsTable: [],
        search: "",
        tableFields: [
          { key: "imageUrl", label: "Img" },
          { key: "id", label: "ID", sortable: true, sortDirection: "desc", class: "align-middle" },
          { key: "sku", label: "SKU", sortable: true, class: "align-middle" },
          { key: "name", label: "Nombre", sortable: true, class: "align-middle" },
          //{ key: "description", label: "Descripcion", sortable: true , class:"align-middle"},
          { key: "category.name", label: "Categoria", sortable: true, class: "align-middle" },
          { key: "price", label: "Precio", sortable: true, class: "align-middle" },
          { key: "status", label: "Status", sortable: true, class: "align-middle" },
          { key: "buttons", class: "align-middle", label: "" },
        ],
      };
    },
    mounted() {

    },
    methods: {
      getAllUsers() {
        let that = this;
        axios
          .get("/rappi/menu/items")
          .then(function (response) {
            that.productsTable = response.data;
          })
          .catch(function (err) {
            that.makeToast("Error", err.request.response, "danger");
          });
      },
      makeToast(title, content, variant) {
        this.$bvToast.toast(content, {
          title,
          variant,
          autoHideDelay: 5000,
          appendToast: true,
        });
      },
    },
  };
  </script>
  