<template>
  <div>
    <b-row>
      <b-col md="6" lg="6" xl="6">
        <h3>Grupos de toppings</h3>
        <p>Los Grupos de Toppings son las agrupaciones de las opciones (ingredientes, adiciones, tamaños, etc.)
          que le das al usuario para que personalice su producto.</p>
        <b-list-group>
          <b-list-group-item v-for="p in productChildrencategories" :key="p.id" class="flex-column align-items-start">
            <div class="d-flex w-100 justify-content-between">
              <b-form-radio v-model="actualChildrencategory" name="category-radios" :value="p">
                {{ p.id + " - " + p.name }}
              </b-form-radio>
              <b-button-group>

                <b-button variant="primary" size="sm" @click="editChildrencategory(p)">
                  <b-icon-pencil-fill></b-icon-pencil-fill>
                </b-button>
                <b-button variant="danger" size="sm" @click="deleteChildrencategory(p.id)">
                  <b-icon-trash-fill></b-icon-trash-fill>
                </b-button>
              </b-button-group>
            </div>
          </b-list-group-item>
          <b-list-group-item>
            <b-button @click="childrencategoryCreateCollapseState = true; cleanChildrencategory()"
              variant="primary">Agregar
              +</b-button>
            <b-collapse id="collapse-create-childrencategory" v-model="childrencategoryCreateCollapseState" class="mt-2">
              <b-card>
                <b-row>
                  <b-col>
                    <h4 class="card-text">Agregar categoria toppings</h4>
                    <p>Busca aqui gurpos de toppings de otros productos o crea uno nuevo mas abajo</p>
                    <vu-select :reduce="(x) => x.id" v-model="selectedChildrencategory"
                      :options="childrencategoriesOptions"></vu-select>
                  </b-col>
                </b-row>
                <b-jumbotron class="my-3 py-3" v-if="!selectedChildrencategory">
                  <b-row>
                    <b-col lg="12">
                      <b-form-group label="Nombre">
                        <b-form-input v-model="actualChildrencategory.name"
                          placeholder="Si no existe inserta el nombre"></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col lg="12">
                      <b-alert variant="success" show>Si tu grupo de toppings es obligatorio, coloca en Min: 1,
                        en caso contrario coloca 0</b-alert>
                    </b-col>
                    <b-col lg="6">
                      <b-form-group label="Max">
                        <b-form-input v-model.number="actualChildrencategory.maxQty" placeholder="Max">
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6">
                      <b-form-group label="Min">
                        <b-form-input v-model.number="actualChildrencategory.minQty" placeholder="Min">
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-jumbotron>
                <b-row class="mt-3">
                  <b-col>
                    <b-button variant="success"
                      @click="createChildrencategory(() => childrencategoryCreateCollapseState = false)">Aceptar
                    </b-button>
                    <b-button variant="danger"
                      @click="childrencategoryCreateCollapseState = false; cleanChildrencategory()">Cancelar
                    </b-button>
                  </b-col>
                </b-row>



              </b-card>
            </b-collapse>

          </b-list-group-item>
        </b-list-group>
      </b-col>
      <b-col md="6" lg="6" xl="6">
        <h3>Toppings</h3>
        <p>Los Topping son las opciones, dentro de un "grupo de toppings", que le das a tu usuario para
          personalizar o modificar el producto.</p>
        <b-list-group>
          <b-list-group-item v-for="p in childrencategoriesadd.filteredToppings" :key="p.id"
            class="flex-column align-items-start">
            <div class="d-flex w-100 justify-content-between">
              <p>{{ p.id + " - " + p.name }} <b-badge v-if="p.status" variant="success">Activo</b-badge><b-badge v-else
                  variant="danger">Inactivo</b-badge></p>
              <b-button-group>
                <b-button variant="primary" size="sm" @click="cleanSubitem(); editTopping(p)">
                  <b-icon-pencil-fill></b-icon-pencil-fill>
                </b-button>
                <b-button variant="danger" size="sm" @click="deleteItemChildren(p.id)">
                  <b-icon-trash-fill></b-icon-trash-fill>
                </b-button>
              </b-button-group>
            </div>

          </b-list-group-item>
          <b-list-group-item>
            <b-button v-if="actualChildrencategory.id != 0" @click="subitemCreateCollapseState = true; cleanSubitem()"
              variant="primary">Agregar +</b-button>
            <b-collapse id="collapse-create-childrencategory" v-model="subitemCreateCollapseState" class="mt-2">
              <b-card>
                <b-row>
                  <b-col>
                    <h4 class="card-text">Agregar Topping</h4>
                    <p>Busca aqui toppings de otros productos o crea uno nuevo mas abajo</p>
                    <vu-select :reduce="(x) => x.id" v-model="selectedTopping" :options="childrenOptions">
                    </vu-select>

                  </b-col>
                </b-row>

                <b-jumbotron class="my-3 py-3" v-if="!selectedTopping">
                  <b-row>
                    <b-col lg="12">
                      <p>Inserta aqui los datos para crear un nuevo topping</p>
                      <b-form-group label="Nombre">
                        <b-form-input v-model="actualChildren.name"
                          placeholder="Si no existe inserta el nombre"></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6">
                      <b-form-group label="Precio">
                        <b-form-input v-model.number="actualChildren.price" placeholder="Precio">
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6">
                      <b-form-group label="Maximo">
                        <b-form-input v-model.number="actualChildren.maxLimit" placeholder="Maximo">
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col xl="12" md="12" sm="12">
                      <b-form-group label="Producto">
                        <vu-select :reduce="(x) => x.id" v-model="actualChildren.product_id"
                          :options="productOptions"></vu-select>
                      </b-form-group>
                    </b-col>
                    <b-col xl="12" md="12" sm="12">
                      <b-form-group label="Personalizador">
                        <vu-select :reduce="(x) => x.id" v-model="actualChildren.personalization_id"
                          :options="personalizationsOptions"></vu-select>
                      </b-form-group>
                    </b-col>
                    <b-col xl="12" md="12" sm="12">
                      <b-form-group label="Status">
                        <b-form-checkbox v-model="actualChildren.status" :value="true" :unchecked-value="false"><b-badge
                            v-if="actualChildren.status" variant="success">Activo</b-badge><b-badge v-else
                            variant="danger">Inactivo</b-badge></b-form-checkbox>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-jumbotron>
                <b-row class="mt-3">
                  <b-col>
                    <b-button variant="success"
                      @click="createItemChildren(() => subitemCreateCollapseState = false)">Aceptar</b-button>
                    <b-button variant="danger" @click="subitemCreateCollapseState = false; cleanSubitem()">Cancelar
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-collapse>
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "ToppingsByProductId",
  data() {
    return {
      statusoptions: [
        { value: true, text: "ACTIVO" },
        { value: false, text: "INACTIVO" },
      ],
      productChildren: [],
      childrenOptions: [],
      productChildrencategories: [],
      actualChildrencategory: {
        id: 0,
        name: "",
        maxQty: 0,
        minQty: 0,
        sorting_position: 1,
        status: true
      },
      actualChildren: {
        id: 0,
        name: "",
        description: "",
        price: null,
        sku: "",
        maxLimit: null,
        sortingPosition: 0,
        type: "TOPPING",
        category_id: null,
        product_id: null,
        personalization_id: null,
        status: true,
        category: {
          id: 0,
          name: "",
          minQty: 0,
          maxQty: 0,
          sortingPosition: 0
        }
      },
      products: [],
      productOptions: [],
      rappiCategories: [],
      rappiChildrencategories: [],
      childrencategoriesOptions: [],
      childrencategoryCreateCollapseState: false,
      subitemCreateCollapseState: false,
      childrencategoriesadd: {
        search: "",
        result: {},
        filteredToppings: [],
      },
      selectedChildrencategory: null,
      selectedTopping: null,
      personalizations: [],
      personalizationsOptions: [],
      rappiCategoriesOptions: [],
    };
  },
  mounted() {
    this.initData()
  },
  props: {
    menuId: Number,
    productId: Number
  },
  watch: {
    actualChildrencategory(n) {
      this.filterProductChildren(n.id)
    }
  },
  methods: {
    initData() {
      this.getChildrenCategories()
      this.getPersonalizations()
      this.getAllChildren()
      this.getAllProducts()
      if (this.productId > 0) {
        console.log("producto no nuevo");
        this.getProductChildren()
        this.getProductChildrencategories()
      }
    },
    getAllProducts() {
      let that = this;
      axios
        .get("/products")
        .then(function (response) {
          that.products = response.data;
          let persfil = response.data
            .filter((pers) => {
              return pers.status;
            })
            .map((pers) => {
              return { id: pers.id, label: pers.clave + " - " + pers.ndescription };
            });
          let persfils = [{ id: null, label: "NINGUNO" }]
          persfils.push(...persfil)
          that.productOptions = persfils;
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    getAllChildren() {
      let that = this
      axios.get("/rappi/menu/" + this.menuId + "/subitems")
        .then(function (response) {
          // that.productChildren = response.data
          let persfil = response.data
            .filter((pers) => {
              return pers.status;
            })
            .map((pers) => {
              return { id: pers.id, label: pers.id + " - " + pers.name };
            });
          let persfils = [{ id: null, label: "NINGUNO" }]
          persfils.push(...persfil)
          that.childrenOptions = persfils
        }).catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        })
    },
    getProductChildren() {
      let that = this
      axios.get("/rappi/menu/" + this.menuId + "/item/" + this.productId + "/subitems")
        .then(function (response) {
          that.productChildren = response.data
          if (that.actualChildrencategory.id != 0) {
            that.filterProductChildren(that.actualChildrencategory.id)
          }
        }).catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        })
    },
    getPersonalizations() {
      let that = this
      axios.get("/personalizations")
        .then(function (response) {
          that.personalizations = response.data
          console.log(response.data)
          let persfil = response.data
            .filter((pers) => {
              return pers.status;
            })
            .map((pers) => {
              return { id: pers.id, label: pers.id + " - " + pers.name };
            });
          let persfils = [{ id: null, label: "NINGUNO" }]
          persfils.push(...persfil)
          that.personalizationsOptions = persfils
        }).catch(function (err) {
          console.log(err)
          that.makeToast("Error", err.request.response, "danger");
        })
    },
    filterProductChildren(n) {
      this.childrencategoriesadd.filteredToppings = this.productChildren.filter(w => w.category_id == n)
    },
    getChildrenCategories() {
      let that = this
      axios.get("/rappi/menu/" + this.menuId + "/children/categories")
        .then(function (response) {
          that.rappiChildrencategories = response.data
          let persfil = response.data
            .map((pers) => {
              return { id: pers.id, label: pers.name };
            });
          let persfils = []
          persfils.push(...persfil)
          that.childrencategoriesOptions = persfils;
        }).catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        })
    },

    getProductChildrencategories() {
      let that = this
      axios.get("/rappi/menu/" + this.menuId + "/item/" + this.productId + "/categories")
        .then(function (response) {
          that.productChildrencategories = response.data
        }).catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        })
    },
    editChildrencategory(c) {
      this.cleanChildrencategory()
      this.actualChildrencategory = c
      this.childrencategoryCreateCollapseState = true
    },
    editTopping(c) {
      this.cleanSubitem()
      this.actualChildren = c
      this.subitemCreateCollapseState = true
    },
    cleanChildrencategory() {
      this.actualChildrencategory =
      {
        id: 0,
        name: "",
        maxQty: 0,
        minQty: 0,
        sorting_position: 1,
        status: true
      }
    },
    cleanSubitem() {
      this.actualChildren =
      {
        id: 0,
        name: "",
        description: "",
        price: null,
        sku: "",
        maxLimit: null,
        sortingPosition: 0,
        type: "TOPPING",
        category_id: null,
        product_id: null,
        personalization_id: null,
        status: true,
        category: {
          id: 0,
          name: "",
          minQty: 0,
          maxQty: 0,
          sortingPosition: 0
        }

      }
    },
    createChildrencategory(f) {

      let that = this;
      if (this.selectedChildrencategory > 0) {
        axios.post("/rappi/menu/" + this.menuId + "/item/" + that.productId + "/children/category/" + this.selectedChildrencategory).then((response) => {
          that.getChildrenCategories()
          that.getProductChildrencategories()
          that.getProductChildren()
          that.makeToast("Exito", response.data.id, "success");
          that.cleanChildrencategory()
        })
        return
      }

      if (this.actualChildrencategory.id > 0) {
        console.log("put Childrencategory");
        axios
          .put(
            "/rappi/menu/" + this.menuId + "/children/category", this.actualChildrencategory
          )
          .then(function (response) {
            that.getChildrenCategories()
            that.getProductChildrencategories()
            that.makeToast("Exito", response.data.id, "success");
            f()
            that.cleanChildrencategory()
          })
          .catch(function (err) {
            that.makeToast("Error", err.request.response, "danger");
          });
      } else {
        console.log("post Childrencategory");
        axios
          .post("/rappi/menu/" + this.menuId + "/children/category", this.actualChildrencategory)
          .then(function (response) {
            axios.post("/rappi/menu/" + that.menuId + "/item/" + that.productId + "/children/category/" + response.data.id).then(() => {
              that.getChildrenCategories()
              that.getProductChildrencategories()
              that.makeToast("Exito", response.data.id, "success");
              f()
              that.cleanChildrencategory()
            })
          })
          .catch(function (err) {
            console.log(err)
            that.makeToast("Error", err.request.response, "danger");
          });
      }
    },
    deleteChildrencategory(category_id) {
      let that = this;
      axios.delete("/rappi/menu/" + this.menuId + "/item/" + this.productId + "/children/category/" + category_id)
        .then((response) => {
          that.getProductChildren()
          that.getChildrenCategories()
          that.getProductChildrencategories()
          that.makeToast("Exito al borrar", response.data, "success");
        })
    },
    deleteItemChildren(subitem_id) {
      let that = this;
      axios.delete("/rappi/menu/" + this.menuId + "/children/category/" + this.actualChildrencategory.id + "/subitem/" + subitem_id)
        .then((response) => {
          that.getChildrenCategories()
          that.getProductChildrencategories()
          that.getProductChildren()
          that.makeToast("Exito al borrar", response.data, "success");

        })
    },
    createItemChildren(f) {
      let that = this;
      if (this.selectedTopping > 0) {
        axios.post("/rappi/menu/" + this.menuId + "/children/category/" + that.actualChildrencategory.id + "/subitem/" + this.selectedTopping).then((response) => {
          that.getChildrenCategories()
          that.getProductChildrencategories()
          that.getProductChildren()
          that.makeToast("Exito", response.data.id, "success");
          that.cleanSubitem()
          f()
          that.makeToast("Exito", response.data.id, "success");
        })
        return
      }

      let error = false
      let errormsj = ""
      if (this.actualChildren.personalization_id == null && this.actualChildren.product_id == null) {
        error = true
        errormsj += "Al menos debes seleccionar una personalizacion o un producto."
      }
      if (this.actualChildren.personalization_id != null && this.actualChildren.product_id != null) {
        error = true
        errormsj += "Solo debes seleccionar personalizacion o producto"
      }
      if (this.actualChildren.maxLimit < 0) {
        error |= true
        errormsj += "El limite maximo debe ser mayor a cero. "
      }
      if (error) {
        this.$bvModal.msgBoxOk(errormsj, {
          title: 'Error',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'success',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true
        })
        return
      }
      if (this.actualChildren.id > 0) {
        console.log("put productpers");
        console.log(this.actualChildren)
        axios
          .put(
            "/rappi/menu/" + this.menuId + "/subitem", this.actualChildren
          )
          .then(function (response) {
            that.getProductChildren()
            that.cleanSubitem()
            f()
            that.makeToast("Exito", response.data.id, "success");
          })
          .catch(function (err) {
            that.makeToast("Error", err.request.response, "danger");
          });
      } else {
        console.log("post productpers");
        axios
          .post("/rappi/menu/" + this.menuId + "/subitem", this.actualChildren)
          .then(function (response) {
            axios.post("/rappi/menu/" + that.menuId + "/children/category/" + that.actualChildrencategory.id + "/subitem/" + response.data.id)
              .then(() => {
                that.getProductChildren()
                that.cleanSubitem()
                f()
                that.makeToast("Exito", response.data.id, "success");
              })

          })
          .catch(function (err) {
            that.makeToast("Error", err.request.response, "danger");
          });
      }
    },
    makeToast(title, content, variant) {
      this.$bvToast.toast(content, {
        title,
        variant,
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
  },
};
</script>
  