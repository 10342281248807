<template>
  <div>
    <Nav></Nav>
    <b-container>
      <b-row>
        <b-col>
          <h1 v-if="actualSupply.id > 0">
            Insumo
            {{ this.actualSupply.id + " " + this.actualSupply.name }}
          </h1>
          <h1 v-else>Crear Insumo</h1>
        </b-col>
      </b-row>
      <b-card>
        <b-form>
          <b-row>
            <b-col md="3" sm="12">
              <b-form-group label="Clave:">
                <b-form-input v-model="actualSupply.code" type="text" required></b-form-input>
              </b-form-group>
            </b-col>
            <b-col xl="9" md="12">
              <b-form-group label="Nombre:">
                <b-form-input v-model="actualSupply.name" type="text" required></b-form-input>
              </b-form-group>
            </b-col>
            <b-col xl="12" md="12">
              <b-form-group label="Descripcion:">
                <b-form-input v-model="actualSupply.description" type="text" required></b-form-input>
              </b-form-group>
            </b-col>
            <b-col xl="6" md="12">
              <b-form-group label="Unidad:">
                <b-form-input v-model="actualSupply.unit" type="text" required></b-form-input>
              </b-form-group>
            </b-col>
            <b-col xl="6" md="12">
              <b-form-group label="Base:">
                <b-form-input v-model.number="actualSupply.base_portion" type="number" required></b-form-input>
              </b-form-group>
            </b-col>
            <b-col lg="12" cols="12">
              <b-form-group label="Categoria" description="Selecciona la categoria">
                <vu-select v-model.number="actualSupply.supply_category_id" :options="supplyCategories" :reduce="(x) => x.id"
                  label="name" @input="getShoppingListItems"></vu-select>
              </b-form-group>
            </b-col>
            <b-col xl="3" md="12">
              <b-form-group label="Status:">
                <b-form-checkbox v-model="actualSupply.status" :value="true" :unchecked-value="false">
                  Activo
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="mt-3">
            <b-button v-if="actualSupply.id > 0" block variant="success" @click="updateProduct">Guardar</b-button>
            <b-button v-else block variant="success" @click="updateProduct">Crear</b-button>
          </b-row>
        </b-form>
      </b-card>



    </b-container>
  </div>
</template>
<script>
import Nav from "../components/Nav.vue";
import axios from "axios";
export default {
  name: "Supply",
  components: { Nav },
  data() {
    return {
      supplyCategories: [],
      show: false,
      actualSupply: {
        id: 0,
        description: "",
        code: "",
        name: "",
        unit: "",
        base_portion: 1,
        status: true,
        supply_category_id:null
      },
    };
  },
  beforeRouteUpdate(to, from) {
    console.log(to);
    console.log(from);
    this.initData();
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      this.getSupplyCategories()
      console.log("creando");
      console.log(this.$route.params.id);
      if (this.$route.params.id > 0) {
        console.log("producto no nuevo");
        this.actualSupply.id = this.$route.params.id;
        this.getSupply();
      }
      console.log("termina creacion");
    },
    getSupplyCategories() {
      let that = this;
      axios
        .get("/supplycategories")
        .then(function (response) {
          that.supplyCategories = response.data;
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    getSupply() {
      let that = this;
      axios
        .get("/supply/" + this.actualSupply.id)
        .then(function (response) {
          that.actualSupply = response.data;
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    updateProduct() {
      let that = this;
      if (this.actualSupply.id > 0) {
        axios
          .put("/supply/" + this.actualSupply.id, this.actualSupply)
          .then(function (response) {
            that.actualSupply = response.data;
            that.makeToast("Exito", "exito al guardar", "success");
            that.getSupply();
            that.$router.push({
              name: "Supplies",
            });
          })
          .catch(function (err) {
            that.makeToast("Error", err.request.response, "danger");
          });
      } else {
        this.actualSupply.status = true
        axios
          .post("/supply", this.actualSupply)
          .then(function (response) {
            that.makeToast("Exito", "exito al crear, redirigiendo", "success");
            console.log(response.data);
            that.$router.push({
              name: "Supply",
              params: { id: response.data.id },
            });
          })
          .catch(function (err) {
            that.makeToast("Error", err.request.response, "danger");
          });
      }
    },

    makeToast(title, content, variant) {
      this.$bvToast.toast(content, {
        title,
        variant,
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
  },
};
</script>
