<template>
  <div>
    <Nav></Nav>
    <b-container>
      <b-row>
        <b-col>
          <h1 v-if="actualProduct.id > 0">
            Mesero
            {{ this.actualProduct.id + " " + this.actualProduct.name }}
          </h1>
          <h1 v-else>Crear Mesero</h1>
        </b-col>
      </b-row>
    
      <b-card>
        <b-form>
          <b-row>
            <b-col md="6" sm="12">
              <b-form-group label="ID:">
                <b-form-input
                  v-model.number="actualProduct.id"
                  type="text"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6" sm="12">
              <b-form-group label="Nombre:">
                <b-form-input
                  v-model="actualProduct.name"
                  type="text"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6" sm="12">
              <b-form-group label="Password:">
                <b-form-input
                  v-model="actualProduct.password"
                  type="text"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6" sm="12">
              <b-form-group label="Tipo:">
                <b-form-input
                  disabled
                  v-model="actualProduct.waiter_type_id"
                  type="text"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col xl="6" md="12">
              <b-form-group label="Status">
                  <b-form-checkbox v-model="actualProduct.status">
                    Activo
                  </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-button
              v-if="actualProduct.id > 0"
              block
              variant="success"
              @click="updateProduct"
              >Guardar</b-button
            >
            <b-button v-else block variant="success" @click="updateProduct">Crear</b-button>
          </b-row>
        </b-form>
      </b-card>
    </b-container>
  </div>
</template>
<script>
import Nav from "../components/Nav.vue";
import axios from "axios";
export default {
  name: "Waiter",
  components: { Nav },
  data() {
    return {
      imgheaders: {
        Authorization: "",
      },
      imgparams: {},
      show: false,
      actualProduct: {
        id: 0,
        name:"",
        short_name:"",
        waiter_type_id:1,
        image: "",
        image_mini: "",
        status: true,
      },
      
    };
  },
  mounted() {
    this.imgheaders.Authorization =
      "Bearer " + localStorage.getItem("user-token");
    if (this.$route.params.id > 0) {
      this.actualProduct.id = this.$route.params.id;
      this.getProduct();
    }
  },
  methods: {
    cropSuccess(_, field) {
      console.log("-------- crop success --------");
      console.log(field);
    },

    cropUploadSuccess(jsonData, field) {
      console.log("-------- upload success --------");
      console.log(jsonData);
      console.log("field: " + field);
      this.getProduct();
    },

    cropUploadFail(status, field) {
      console.log("-------- upload fail --------");
      console.log(status);
      console.log("field: " + field);
    },

    getProduct() {
      let that = this;
      axios
        .get("/waiter/" + this.actualProduct.id)
        .then(function (response) {
          that.actualProduct = response.data;
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    updateProduct() {
      let that = this;
      if(this.$route.params.id > 0){
      axios
        .put("/waiter/" + this.actualProduct.id, this.actualProduct)
        .then(function (response) {
          that.actualProduct = response.data;
          that.makeToast("Exito", "exito al guardar", "success");
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
      }else{
        axios
        .post("/waiter", this.actualProduct)
        .then(function (response) {
          that.actualProduct = response.data;
          that.makeToast("Exito", "exito al crear", "success");
          that.$router.push("/waiter/"+response.data.id).catch();
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
      }
    },
    makeToast(title, content, variant) {
      this.$bvToast.toast(content, {
        title,
        variant,
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
  },
};
</script>
