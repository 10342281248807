<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col>
          <h2>Categorias:</h2>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button size="sm" variant="success" v-b-modal.modal-createUberCategory>Crear
            Categoria</b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table responsive striped hover :items="categories" :fields="tableFields">
            <template #cell(buttons)="data">
              <b-button-group vertical>
                <b-button size="sm" variant="warning" @click="editCategory(data.item.id)"
                  >Editar</b-button
                >
              </b-button-group>
            </template>
            <template #cell(items)="data">
              {{getItemNamesFromIds(data.item.entities)}}
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-container>
    <b-modal id="modal-createUberCategory" title="Crear Categoria" @ok="createCategory">
      <b-row>
        <b-col>
          <p>Aqui puedes crear las categorias.</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group label="Nombre:">
            <b-form-input v-model="actualCategory.title.translations.en" type="text" required></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group label="Poductos">
            <vu-select multiple :reduce="(x) => x.value" v-model="actualCategory.entities" :options="items"></vu-select>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "UberCategories",
  data() {
    return {
      categories: [],
      items:[],
      actualCategory: {
        id: "",
        title: {
            translations: {
                en: ""
            }
        },
        entities: [
        ]
      },
      search: "",
      tableFields: [
        { key: "title.translations.en", label: "Titulo" },
        { key: "items", label: "Articulos" },
        { key: "buttons", label: "" }
      ]
    };
  },
  mounted() {
    this.getAllCategories();
    this.getAllItems()
  },
  methods: {
    getItemNamesFromIds(ids){
      console.log("getItemNamesFromIds")
      let items=ids.reduce(function(acum,el){
        acum.push(el.id)
        return acum
      },[])
      console.log(items)
      let x= this.items.reduce(function(p,x){
        if(items.includes(x.id)){
          return x.title.translations.en+(p==""?"":", ")+p
        }else{
          return p
        }
      },"")
      return x
    },
    editCategory(id){
      let that = this;
      this.actualCategory.id=id
      axios
        .get("/uber/mastermenu/" + this.$route.params.id + "/category/"+this.actualCategory.id)
        .then(function (response) {
          that.actualCategory = response.data;
          that.$bvModal.show("modal-createUberCategory")
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    getAllCategories() {
      let that = this;
      axios
        .get("/uber/mastermenu/" + this.$route.params.id + "/categories")
        .then(function (response) {
          that.categories = response.data;
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    getAllItems() {
      let that = this;
      axios
        .get("/uber/mastermenu/" + this.$route.params.id + "/items")
        .then(function (response) {
          console.log(response.data)
          let x=response.data
          let y=x.map(function(el){
            el.label=el.title.translations.en
            el.value={id:el.id}
            return el
          })
          that.items = y
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    createCategory() {
      let that = this;
      let verb = ""
      if (this.actualCategory.id.length > 0) {
        verb = "PUT"
      } else {
        verb = "POST"
      }
      console.log(verb)
      axios({ method: verb, url: "/uber/mastermenu/" + this.$route.params.id + "/category", data: this.actualCategory })
        .then(function (response) {
          that.actualCategory = response.data;
          that.getAllCategories()
          that.resetactualCategory()
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    getMenu() {
      let that = this;
      axios
        .get("/uber/mastermenu/" + this.$route.params.id + "/category/"+this.actualCategory.id)
        .then(function (response) {
          that.actualCategory = response.data;
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    resetactualCategory() {
      this.actualCategory = {
        id: "",
        title: {
            translations: {
                en: ""
            }
        },
        entities: [
        ]
      }
    },
    makeToast(title, content, variant) {
      this.$bvToast.toast(content, {
        title,
        variant,
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
  },
};
</script>
  