import axios from 'axios'

const state = {
    products: [],
    groups: [],
    paymentMethods: [],
    activeTickets: [],
    activeTicket: {},
    restaurantAreas: [],
    restAreaLabels: [],
    token: localStorage.getItem('user-token') || '',
    status: '',
    imageLink: '',
    actualTicket: 0,
    loginInfo: {
        user: {
            "username": "",
            "name": ""
        },
        actions:[]
    },
    userActions:[],
    discount_types: []
};

const getters = {
    allRestAreasLabels: (state) => state.restAreaLabels,
    allPaymentMethods: (state) => state.paymentMethods,
    allDiscounts: (state) => state.discount_types,
    allProducts: (state) => state.products,
    allGroups: (state) => state.groups,
    allActiveTickets: (state) => state.activeTickets,
    allRestAreas: (state) => state.restaurantAreas,
    isAuthenticated: state => !!state.token,
    authStatus: state => state.status,
    imgLink: state => state.imageLink,
    activeTicket: state => state.actualTicket,
    loginInfo: state => state.loginInfo,
};

const actions = {
    logout({ commit }) {
        localStorage.removeItem('user-token') // clear your user's token from localstorage
        const token = localStorage.getItem('user-token')
        axios.defaults.headers.common['Authorization'] = "Bearer " + token
        localStorage.removeItem("login-info")
        commit("LOGOUT")
        return true
    },
    login({ commit }, user) {
        // let that=this
        return new Promise((resolve, reject) => { // The Promise used for router redirect in login
            commit("AUTH_REQUEST")
            axios({ url: '/user/login', data: user, method: 'POST' })
                .then(resp => {
                    const token = resp.data.access_token
                    localStorage.setItem('user-token', token) // store the token in localstorage
                    axios.defaults.headers.common['Authorization'] = "Bearer " + token
                    commit("AUTH_SUCCESS", token)
                    localStorage.setItem("login-info", JSON.stringify(resp.data))
                    let loginInfo=resp.data
                    axios.get('/user/'+loginInfo.user.id+"/actions")
                    .then(resp=>{
                        loginInfo.actions=resp.data
                        commit("SET_LOGININFO", loginInfo)
                    }).catch(err=>{
                        console.log(err)
                        commit("SET_LOGININFO", loginInfo)
                    })

                    // you have your token, now log in your user :)
                    resolve(resp)
                })
                .catch(err => {
                    localStorage.removeItem('user-token') // if the request fails, remove any possible user token if possible
                    reject(err)
                })
        })
    },
    getLoginData({ commit }) {
        if (localStorage.getItem('login-info') !== null) {
            commit("SET_LOGININFO", JSON.parse(localStorage.getItem('login-info')))
        } else {
            commit("SET_LOGININFO", {
                user: {
                    "username": "",
                    "name": ""
                }
            })
        }
    },
    getDiscountTypes({ commit }) {
        axios.get('/discounts')
            .then(response => {
                commit('SET_DISCOUNTS', response.data)
            }).catch(function () {

            })
    },
    getPaymentMethods({ commit }) {
        axios.get('/paymentmethods')
            .then(response => {
                commit('SET_PAYMENTMETHODS', response.data)
            }).catch(function () {

            })
    },
    getRestAreas({ commit }) {
        axios.get('/restaurant/areas')
            .then(response => {
                commit('SET_RESTAREAS', response.data)
            }).catch(function () {

            })
    },
    getRestAreasLabels({ commit }) {
        axios.get('/restaurant/areas/labels')
            .then(response => {
                commit('SET_RESTAREASLABELS', response.data)
            }).catch(function () {

            })
    },
    getProducts({ commit }) {
        axios.get('/products')
            .then(response => {
                commit('SET_PRODUCTS', response.data)
            })
    },
    getActivetickets({ commit }) {
        axios.get('/tickets/active')
            .then(response => {
                commit('SET_ACTIVETICKETS', response.data)
            })
    },
    getGroups({ commit }) {
        axios.get('/groups')
            .then(response => {
                commit('SET_GROUPS', response.data)
            })
    },
    setActualTicket({ commit }, ticektId) {
        commit("SET_ACTIVE_TICKET", ticektId)
    }
};

const mutations = {
    SET_PAYMENTMETHODS(state, paym) { state.paymentMethods = paym },
    SET_ACTIVE_TICKET(state, ticketId) { state.actualTicket = ticketId },
    SET_DISCOUNTS(state, discounts) { state.discount_types = discounts },
    SET_LOGININFO(state, info) { state.loginInfo = info },
    SET_PRODUCTS(state, products) {
        state.products = products
    },
    SET_GROUPS(state, groups) {
        state.groups = groups
    },
    SET_ACTIVETICKETS(state, tickets) {
        state.activeTickets = tickets
    },
    SET_RESTAREAS(state, areas) { state.restaurantAreas = areas },
    SET_RESTAREASLABELS(state, areas) { state.restAreaLabels = areas },
    AUTH_REQUEST: (state) => {
        state.status = 'loading'
    },
    AUTH_SUCCESS: (state, token) => {
        state.status = 'success'
        state.token = token
    },
    AUTH_ERROR: (state) => {
        state.status = 'error'
    },
    LOGOUT: (state) => {
        state.status = ''
        state.token = ''
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};